import heroImage from '../assets/images/heroImage.png';
import b2bBanner1 from '../assets/images/b2b_banner_1.png';
import b2bBanner2 from '../assets/images/b2b_banner_2.png';
import b2bBanner3 from '../assets/images/b2b_banner_3.svg';
import b2bBanner4 from '../assets/images/b2b_banner_4.png';
import step1 from '../assets/images/b2b_step1.svg';
import step2 from '../assets/images/b2b_step2.svg';
import step3 from '../assets/images/b2b_step3.svg';
import banners1 from '../assets/images/banners1.png';
import banners2 from '../assets/images/banners2.png';
import Banner160 from '../assets/images/160Banner.png';
import Banner1602 from '../assets/images/160Banner1.png';
import step1Expanded from '../assets/images/b2b_step1_expanded.svg';
import step2Expanded from '../assets/images/b2b_step2_expanded.svg';
import step3Expanded from '../assets/images/b2b_step3_expanded.svg';
import step1Mobile from '../assets/images/b2b_step1_mobile.svg';
import step2Mobile from '../assets/images/b2b_step2_mobile.svg';
import step3Mobile from '../assets/images/b2b_step3_mobile.svg';
import b2bCompanies from '../assets/images/b2b_companies.png';
import privacyIcon1 from '../assets/images/privacy_icon_1.svg';
import privacyIcon2 from '../assets/images/privacy_icon_2.svg';
import privacyIcon3 from '../assets/images/privacy_icon_3.svg';
import IosStoreLogo from '../assets/images/ios_app_store_logo.png';
import AndroidStoreLogo from '../assets/images/android_app_store_logo.png';
import WhiteLogo from '../assets/images/white_logo.svg';
import GoLive1 from '../assets/images/go_live_1.svg';
import GoLive2 from '../assets/images/go_live_2.svg';
import GoLive3 from '../assets/images/go_live_3.svg';
import GoLive4 from '../assets/images/go_live_4.svg';
import PricingBG from '../assets/images/pricing_bg.svg';
import PricingBGMobile from '../assets/images/pricing_bg_mobile.png';
import AboutUsBanner1 from '../assets/images/about_us_banner1.png';
import AboutUsBanner2 from '../assets/images/about_us_banner2.png';
import PersonalisedWebsite from '../assets/images/personalised_website-cropped.svg';
import contact from '../assets/images/contact.svg';
import envelope from '../assets/images/envelope.svg';
import message from '../assets/images/message.svg';
import entity from '../assets/images/entity.svg';
import ihop from '../assets/images/merchant_logos/ihop.svg';
import bestBuy from '../assets/images/merchant_logos/bestBuy.svg';
import applebees from '../assets/images/merchant_logos/applebees.svg';
import outback from '../assets/images/merchant_logos/outback.svg';
import booking from '../assets/images/merchant_logos/booking.svg';
import macys from '../assets/images/merchant_logos/macys.svg';
import walmart from '../assets/images/merchant_logos/walmart.svg';
import expedia from '../assets/images/merchant_logos/expedia.svg';
import riteAid from '../assets/images/merchant_logos/riteAid.svg';
import cvsPharmacy from '../assets/images/merchant_logos/cvsPharmacy.svg';
import newegg from '../assets/images/merchant_logos/newegg.svg';
import sephora from '../assets/images/merchant_logos/sephora.svg';
import temu from '../assets/images/merchant_logos/temu.svg';
import samsClub from '../assets/images/merchant_logos/samsClub.svg';
import target from '../assets/images/merchant_logos/target.svg';
import kroger from '../assets/images/merchant_logos/kroger.svg';
import apple from '../assets/images/merchant_logos/apple.svg';
import hotels from '../assets/images/merchant_logos/hotels.svg';
import jd from '../assets/images/merchant_logos/jd.svg';
import QRCodeDashboard from '../assets/images/dashboard/qr-code 1.png';
import iosBlackBG from '../assets/images/dashboard/ios-black-bg.png';
import iosWhiteBG from '../assets/images/dashboard/ios-white-bg.png';
import androidWhiteBG from '../assets/images/dashboard/android-white-bg.png';
import androidBlackeBG from '../assets/images/dashboard/android-black-bg.png';
import comingSoon from '../assets/images/coming soon.png';
export const images = {
  comingSoon,
  QRCodeDashboard,
  iosBlackBG,
  iosWhiteBG,
  androidWhiteBG,
  androidBlackeBG,
  heroImage,
  b2bBanner1,
  b2bBanner2,
  b2bBanner3,
  b2bCompanies,
  step1,
  step2,
  step3,
  banners1,
  banners2,
  Banner160,
  Banner1602,
  step1Expanded,
  step2Expanded,
  step3Expanded,
  step1Mobile,
  step2Mobile,
  step3Mobile,
  privacyIcon1,
  privacyIcon2,
  privacyIcon3,
  b2bBanner4,
  IosStoreLogo,
  AndroidStoreLogo,
  WhiteLogo,
  GoLive1,
  GoLive2,
  GoLive3,
  GoLive4,
  PricingBG,
  PricingBGMobile,
  AboutUsBanner1,
  AboutUsBanner2,
  PersonalisedWebsite,
  contact,
  envelope,
  message,
  entity,
  ihop,
  bestBuy,
  applebees,
  outback,
  booking,
  macys,
  walmart,
  expedia,
  riteAid,
  cvsPharmacy,
  newegg,
  sephora,
  temu,
  samsClub,
  target,
  kroger,
  apple,
  hotels,
  jd,
};
