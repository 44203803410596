import React, { useState } from 'react';
import Table from '../../components/table/Table';
import { config2, data2 } from '../../assets/dummy';
import { FaUsers } from 'react-icons/fa6';
import { AiOutlineTransaction } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { IoCardOutline } from 'react-icons/io5';
import BarchartComp from '../../components/barChart/BarChart';
import LineChartComp from '../../components/lineChart/LineChart';
import { JSX } from 'react/jsx-runtime';
import { VscGraph } from 'react-icons/vsc';
import { FaRegRectangleList } from 'react-icons/fa6';

const Reporting = () => {
  const [selectedCircle, setSelectedCircle] = useState<number | null>(1);
  const [view, setView] = useState<'charts' | 'list'>('charts');
  const [currentTab, setCurrentTab] = useState<any>({
    id: 1,
    name: 'Total Customers',
    value: '8465',
    description: '+20.1% from last month',
    icon: <FaUsers size={24} />,
  });

  const handleCircleClick = (selectedTab: {
    id: any;
    name?: string;
    value?: string;
    description?: string;
    icon?: JSX.Element;
  }) => {
    setSelectedCircle(selectedTab.id);
    setCurrentTab(selectedTab);
    setView('charts');
  };

  const tabs = [
    {
      id: 1,
      name: 'Customers',
      value: '8465',
      description: '+20.1% from last month',
      icon: <FaUsers size={24} />,
    },
    {
      id: 2,
      name: 'Transactions',
      value: '954',
      description: '+20.1% from last month',
      icon: <AiOutlineTransaction size={24} />,
    },
    {
      id: 3,
      name: 'Partner Earnings',
      value: '$45,231.45',
      description: '+20.1% from last month',
      icon: <BsCurrencyDollar size={24} />,
    },
    {
      id: 4,
      name: 'Customer Earnings',
      value: '$400',
      description: '+20.1% from last month',
      icon: <BsCurrencyDollar size={24} />,
    },
    {
      id: 5,
      name: 'Enrolled Cards',
      value: '513',
      description: '+20.1% from last month',
      icon: <IoCardOutline size={24} />,
    },
  ];

  return (
    <div className='container mx-auto p-4 text-center'>
      {/* Circles */}
      <div className='flex flex-wrap justify-center gap-[4.5rem] xmd:[2.5rem] mb-8'>
        {tabs.map(tab => (
          <div className='flex flex-col justify-evenly'>
            <div
              key={tab.id}
              className={`flex flex-col items-center justify-center cursor-pointer w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full transition-all duration-300 ease-in-out 
  ${
    tab.id === selectedCircle
      ? 'border-[#38c99f] border-[4px] shadow-[rgba(0,_0,_0,_2.24)_0px_3px_8px] bg-[#ffffff] text-primary'
      : 'border-primary border-[4px] hover:shadow-[rgba(0,_0,_0,_2.24)_0px_3px_8px] bg-[#ffffff] text-primary'
  }`}
              onClick={() => handleCircleClick(tab)}
            >
              {tab.icon}
              <div className='text-xl mt-2'>{tab.value}</div>
            </div>

            <div
              className={`${
                tab.id === selectedCircle ? 'text-primary' : 'text-primary'
              } font-semibold mt-6 `}
            >
              {tab.name}
            </div>
          </div>
        ))}
      </div>
      <div className='flex flex-row justify-between align-middle center mb-[5rem] mt-[5rem]'>
        <h1 className=' font-[500] text-[2rem]'>{currentTab.name}</h1>
        <div>
          <button
            className={`px-5 py-2 text-[1rem] ${
              view === 'charts'
                ? 'bg-primary text-white border-white px-4 py-[9px]'
                : 'bg-white text-primary border-[#c4c4e5] border-[1px] px-4 py-2'
            }`}
            onClick={() => setView(view === 'charts' ? 'list' : 'charts')}
          >
            <VscGraph />
          </button>
          <button
            className={`${
              view === 'list'
                ? 'bg-primary text-white border-white px-4 py-[9px]'
                : 'bg-white text-primary border-[#c4c4e5] border-[1px] px-4 py-2 '
            } text-[1rem]`}
            onClick={() => setView(view === 'charts' ? 'list' : 'charts')}
          >
            <FaRegRectangleList />
          </button>
        </div>
      </div>

      {/* Display Charts or List View */}
      {/* Toggle Button */}

      {view === 'charts' && (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
          <BarchartComp title='Customers Count' />
          <LineChartComp title='Transactions Count' />
        </div>
      )}

      {view === 'list' && (
        <div className='p-4'>
          <Table
            data={data2}
            config={config2}
            showExportButton={true}
            selectCheckbox={false}
            allDataUrl='recent-transactions'
            width='w-full'
          />
        </div>
      )}
    </div>
  );
};

export default Reporting;
