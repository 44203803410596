// NotificationsInbox.tsx
import React, { useState } from 'react';
import {
  FiCheckCircle,
  FiXCircle,
  FiInfo,
  FiAlertCircle,
} from 'react-icons/fi';
import { Notification } from '../../utils/type';
import Button from '../../components/button/Button';
import { Link } from 'react-router-dom';

const NotificationIcon: React.FC<{ type: Notification['type'] }> = ({
  type,
}) => {
  switch (type) {
    case 'success':
      return <FiCheckCircle className='text-green-500 w-6 h-6' />;
    case 'error':
      return <FiXCircle className='text-red-500 w-6 h-6' />;
    case 'info':
      return <FiInfo className='text-blue-500 w-6 h-6' />;
    case 'warning':
      return <FiAlertCircle className='text-yellow-500 w-6 h-6' />;
    default:
      return null;
  }
};

const NotificationsInbox: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      id: 1,
      title: 'System Update',
      message: 'Your system has been successfully updated.',
      timestamp: '2024-10-10 12:45',
      isRead: false,
      type: 'info',
    },
    {
      id: 2,
      title: 'New Message',
      message:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos quaerat obcaecati quibusdam, id consectetur suscipit assumenda? Commodi atque, maiores temporibus vitae suscipit totam dignissimos deleniti fuga distinctio ullam explicabo fugit odio harum iure culpa tempora laborum ipsum ex necessitatibus debitis vel illum eaque! Commodi, optio et? In quis error vero debitis rerum quo ex nihil laudantium ipsa, explicabo cumque, harum beatae. Officia ut, minima dolor ratione velit ducimus autem earum, minus eaque quod quas sequi quam corporis id reprehenderit accusantium!',
      timestamp: '2024-10-11 09:30',
      isRead: true,
      type: 'success',
    },
    {
      id: 3,
      title: 'Password Expiration',
      message: 'Your password will expire in 5 days.',
      timestamp: '2024-10-12 07:20',
      isRead: false,
      type: 'warning',
    },
    {
      id: 4,
      title: 'System Update',
      message: 'Your system has been successfully updated.',
      timestamp: '2024-10-10 12:45',
      isRead: false,
      type: 'info',
    },
    {
      id: 5,
      title: 'New Message',
      message: 'You have received a new message from John.',
      timestamp: '2024-10-11 09:30',
      isRead: true,
      type: 'success',
    },
    {
      id: 6,
      title: 'Password Expiration',
      message: 'Your password will expire in 5 days.',
      timestamp: '2024-10-12 07:20',
      isRead: false,
      type: 'warning',
    },
  ]);

  return (
    <div className='p-6 bg-[#F8F9FF]'>
      <div className='rounded-lg md:w-[50%] mx-auto'>
        <h2 className='text-2xl font-semibold mb-10'>Notifications Inbox</h2>

        <div className='grid grid-cols-1 gap-4'>
          {notifications.map(notification => (
            <Link
              to={`/notification/${notification.id}`}
              key={notification.id}
              className={`p-4 rounded-lg flex items-start space-x-4 ${
                notification.isRead
                  ? 'shadow-sm bg-white'
                  : 'opacity-100 bg-[#E3F2FD] shadow-md'
              }`}
            >
              {/* Icon */}
              <div className='flex-shrink-0'>
                <NotificationIcon type={notification.type} />
              </div>

              {/* Notification Content */}
              <div className='flex-grow'>
                <h3 className='text-lg font-medium'>{notification.title}</h3>
                <p className='text-sm text-gray-600 line-clamp-1 mb-2'>
                  {notification.message}
                </p>
                <span className='text-xs text-gray-500'>
                  {notification.timestamp}
                </span>
              </div>

              {/* Read Status */}
              {notification.isRead && (
                <span className='text-xs text-green-500'>Read</span>
              )}
            </Link>
          ))}
        </div>

        {/* Buttons */}
        <div className='flex justify-end mt-6 space-x-2'>
          <Button
            onClick={() =>
              setNotifications(notifications.map(n => ({ ...n, isRead: true })))
            }
            primary
          >
            Mark All as Read
          </Button>
          <Button
            onClick={() => setNotifications([])}
            // className='px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600'
            secondary
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsInbox;
