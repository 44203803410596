import SignUp from '../signup/Main';
import BrandingInformation from '../../components/branding/BrandingInformation';

const InactiveDashboard = () => {
  return (
    <>
      <SignUp />
      <BrandingInformation />
    </>
  );
};
export default InactiveDashboard;
