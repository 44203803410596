import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';
import Subscription from './Subscription';
import Payment from './Payment';
import { MdOutlineDone } from 'react-icons/md';

import SignupForm from '../../components/signup/SignupForm';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import Spinner from '../../components/spinner/Spinner';
// import Branding from './Branding';
// import FinalStep from './FinalStep';

const SignUp = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentSessionId, setPaymentSessionId] = useState<string | null>(null);
  const userStatus = useSelector((state: RootState) => state.auth.status);
  const [isLoading, setIsLoading] = useState(false);
  const [step1, setStep1] = useState({
    data: null,
    submit: false,
    completed: false,
  });

  // const [step2, setStep2]=useState(null);
  // const [step3, setStep3]=useState(null);
  // const [step4, setStep4]=useState(null);
  // const formRef = useRef<{ validateFields: () => boolean }>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState(false);
  const steps = [
    { id: 1, name: 'Registration' },
    { id: 2, name: 'Subscription' },
    { id: 3, name: 'Payment' },
    { id: 4, name: 'Program Information' },
    { id: 5, name: 'Review' },
    { id: 6, name: 'Go-Live' },
  ];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  useEffect(() => {
    if (sessionId && sessionId !== null && sessionId !== undefined) {
      setPaymentSessionId(sessionId);
      setCurrentStep(3);
    }
  }, [sessionId]);

  useEffect(() => {
    if (window.location.pathname.includes('dashboard')) {
        if(userStatus === 'null') {
          setCurrentStep(0);
        }
        if (userStatus === 'inreview') {
          setCurrentStep(5);
        } else if (userStatus === 'inactive') {
          setCurrentStep(4);
        }
    }
  }, [userStatus]);

  const registerUser = () => {
    setStep1(prevState => ({
      ...prevState,
      submit: true,
    }));
  };

  useEffect(() => {
    // Check if currentStep is 2 and paymentSelected is false
    if (currentStep === 2 && !paymentSelected) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [currentStep, paymentSelected]);

  const handleNext = () => {
    setIsLoading(true);
    try {
      if (currentStep === 1) {
        registerUser();
        if (step1.completed) {
          if (currentStep < steps.length) setCurrentStep(currentStep + 1);
        }
        return;
      }
      if (currentStep < steps.length) setCurrentStep(currentStep + 1);
    } catch (er) {
      console.log(er);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step1.completed) {
      setCurrentStep(prevStep => {
        if (prevStep < steps.length - 1) {
          return prevStep + 1;
        }
        return prevStep;
      });
    }
  }, [step1.completed, steps.length]);

  const handleBack = () => {
    setStep1(prevState => ({
      ...prevState,
      completed: false,
    }));
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {/* Stepper Container */}
      <div className='hidden md:flex justify-center items-center my-6 w-full'>
        {/* Container divided into 6 equal sections */}
        <div className='flex justify-around w-full'>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex flex-col justify-center items-center w-1/6 py-4 ${
                index < currentStep ? 'bg-[#F8F9FF]' : 'bg-[#f6f6f6]'
              }`}
            >
              {/* Step Circle */}
              <div
                className={`flex justify-center items-center w-10 h-10 rounded-full border-2 ${
                  index < currentStep
                    ? 'bg-[#1133BB] text-white border-[#1133BB]'
                    : index === currentStep - 1
                      ? 'bg-[#1133BB] text-white border-[#1133BB] border-[4px]'
                      : 'border-[#969696] text-[#969696]'
                } font-semibold`}
              >
                {index < currentStep - 1 || paymentSessionId ? (
                  <MdOutlineDone className='text-[#white]' />
                ) : (
                  index + 1
                )}
              </div>
              {/* Step Name */}
              <span
                className={`mt-2 text-[0.75rem] xmd:text-sm  md:text-[0.75rem] font-medium ${
                  index < currentStep
                    ? ' text-[#1133BB] '
                    : index === currentStep - 1
                      ? 'text-[#1133BB]'
                      : 'text-gray-600'
                }`}
              >
                {step.name}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Step Content */}
      <div className='px-[10px] md:px-auto md:container mx-auto my-8 mb-32'>
        {currentStep === 0 && (
          <Spinner />
        )}
        {currentStep === 1 && (
          <div>
            <h2 className='text-2xl font-semibold mb-4 px-4'>
              1 - Registration
            </h2>
            <SignupForm formData={step1} setFormData={setStep1} />
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <h2 className='text-2xl font-semibold mb-4 px-4'>
              2 - Subscription
            </h2>
            <Subscription
              paymentSelected={paymentSelected}
              setPaymentSelected={setPaymentSelected}
            />
          </div>
        )}
        {currentStep === 3 && (
          <div>
            {!paymentSessionId && (
              <h2 className='text-2xl font-semibold mb-4 px-4'>3 - Payment</h2>
            )}
            <Payment paymentSessionId={paymentSessionId} />
          </div>
        )}
        {/* Additional steps are commented out */}
        {/* 
        {currentStep === 4 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">4: Program Information</h2>
            <Branding />
          </div>
        )}
        {currentStep === 5 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">5. Review</h2>
            <FinalStep />
          </div>
        )} 
        */}

        {/* Navigation Buttons */}
        {(currentStep === 1 || currentStep === 2 || currentStep === 3) && (
          <div className='flex justify-end mt-8 px-4 gap-4'>
            {!paymentSessionId && (
              <button
                onClick={handleBack}
                hidden={currentStep === 1 || currentStep === 2}
                className='px-4  bg-[#E6E6E6] hover:bg-gray-300 text-[#7F7F7F] py-2 w-[7rem] font-semibold rounded-[10px]'
              >
                Back
              </button>
            )}
            {currentStep === 2 ? (
              <Button
                type='submit'
                disabled={isButtonDisabled}
                onClick={handleNext}
                className={`${
                  currentStep === steps.length ? 'hidden' : 'block'
                } w-full md:w-auto text-sm px-5 text-white ${
                  !paymentSelected
                    ? 'bg-[#a3a3a3] border-[#a3a3a3]'
                    : 'bg-primary border-primary'
                } min-w-[7rem] py-2 w-[7rem] rounded-[10px] font-semibold`}
              >
                {paymentSessionId
                  ? 'Ok'
                  : currentStep === steps.length - 1
                    ? 'Submit'
                    : 'Next'}
              </Button>
            ) : (
              <Button
                type='submit'
                onClick={() =>
                  paymentSessionId ? navigate('/') : handleNext()
                }
                primary
                className={`${
                  currentStep === steps.length ||
                  (currentStep === 3 && !paymentSessionId)
                    ? 'hidden'
                    : 'block'
                }  md:w-auto text-sm px-5  min-w-[7rem] py-2 w-[7rem] rounded-[10px] font-semibold`}
                isLoading={isLoading}
                disabled={isLoading}
              >
                {paymentSessionId
                  ? 'Ok'
                  : currentStep === steps.length - 1
                    ? 'Submit'
                    : isLoading
                      ? 'Next...'
                      : 'Next'}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SignUp;
