import Footer from '../b2b/Footer';
import React, { useEffect } from 'react';
type ScrollComponentProps = {
  scrollToId: 'terms' | 'privacy';
};
const PrivacyPolicyPage: React.FC<ScrollComponentProps> = ({ scrollToId }) => {
  useEffect(() => {
    const hash = window.location.hash;
    console.log(10, hash);
    if (hash) {
      const element = document.querySelector(hash);

      console.log(11, element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      <div className='container-fluid md:container md:mx-12 mb-8'>
        <section id='termsOfUse'>
          <h2 className=' text-[1.25rem] leading-[2rem] md:text-[40px] text-[#2D2D2D] md:leading-[40px] font-[400]'>
            Customer Loyalty Rewards Program Agreement
          </h2>
          <p className='text-base font-[400] my-8'>
            Effective Date: 1st September, 2024
          </p>

          <div className='term'>
            {/* New point */}
            {/* <h3 className='font-bold mb-2 py-4 pt-10'>1. Validity</h3> */}
            <p className='text-base text-gray-700'>
              The following Terms and Conditions (hereinafter referred to as
              “Terms” or “Agreement”) of{' '}
              <span className='font-bold'>
                MAD REWARDS INC., D/B/A SAVERS CLUB{' '}
              </span>{' '}
              (hereinafter
              <span className='font-bold'> “Operator”</span> ), apply to all
              users (hereafter to as{' '}
              <span className='font-bold'>“Participant” </span>)of services
              provided by Operator (hereafter referred to as “Customer Loyalty
              Rewards Program”). The Participant declares his/her/its consent
              with the Terms through their use of aforementioned Customer
              Loyalty Rewards Program. Any Terms and conditions set forth by the{' '}
              <span className='font-bold'>Participant</span> or any third
              parties shall be void and do not apply, even if
              <span className='font-bold'> Operator</span> does not contradict
              their application on an individual basis{' '}
              <span className='font-bold'>Operator </span>
              reserves the right to modify its Terms from time to time solely at
              its discretion.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              1. SCOPE OF SERVICES/CONTENT.
            </h3>
            <p className='text-base text-gray-700'>
              The Operator hereby associates with the Participant and the
              Participant hereby accepts such terms of association as set forth
              in this Agreement for the establishment of a Co-Branded Customer
              Loyalty Rewards Program. Operator warrants that it will create and
              operate a co-branded Participant customer engagement platform
              (hereinafter “App”) in Participants’ name, that may be offered to
              Participants’ Customers.
            </p>
            {/* <br />
            <p className='text-base text-gray-700'>
              MAD REWARDS operates the Partner Program, including all service
              components thereof, as well as customer service and Partner
              Program content management.
            </p>
            <br />
            <p className='text-base text-gray-700'>
              The Partner shall, to the best of his/her/its ability, endeavor to
              register new Participants on the Partner Program.
            </p>
            <br />
            <p className='text-base text-gray-700'>
              The Partner receives a commission payment according to Section 8
              of these Terms from the rewards earned by Participants who have
              registered through the Partner Program. Participants’ data remains
              the property of MAD REWARDS; the Partner receives a limited right
              to use the database. The extent of the Partner’s right of use is
              defined by tools made available in the Partner’s ‘back office’
              portal provided by MAD REWARDS. All contractual relationships with
              Participants, as Participants, are solely between the Participants
              and MAD REWARDS, to the exclusion of Partner.
            </p>
            <br />
            <p className='text-base text-gray-700'>
              Notwithstanding anything to the contrary, Partner’s registration
              in the Partner Program is fully contingent upon and subject to
              Partner’s completion of the Partner registration forms provided by
              MAD REWARDS (which may consist of online and/or other forms) and
              MAD REWARDS’s acceptance of Partner into the Partner Program,
              which acceptance shall be at MAD REWARDS’s sole discretion.
            </p>
            <br />
            <p className='text-base text-gray-700'>
              Participant registration is web-based or APP based and is
              therefore not necessarily geographically limited; provided,
              however, that it is the Participant’s obligation to ensure that
              registration on and use of the Partner Program is legally
              permissible within their jurisdiction. The offering shall begin on
              the date that the Partner Program is made publicly available
              (which date is estimated to be up to 6 weeks after MAD REWARDS
              accepts the Partner’s completed registration and applicable
              payments have been processed as described in Section 5 herein.
            </p>
            <br /> */}

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              2. CUSTOMER LOYALTY REWARDS PROGRAM DESIGN, DOMAIN, MOBILE
              APPLICATION, AND HOSTING FEES.
            </h3>
            <p className='text-base text-gray-700'>
              In order to set up the Customer Loyalty Rewards Program,
              Participant shall pay to Operator a setup fee as per the current
              fee schedule published by Operator. For continuous operation of
              Customer Loyalty Rewards Program, Participant shall pay to
              Operator a monthly service fee. If Participant wishes to create an
              additional Customer Loyalty Rewards Program, then Participant will
              be charged an additional setup and monthly service fee associated
              with the new program. The Participant will be invoiced and charged
              for the setup fee upon registration for the Customer Loyalty
              Rewards Program, followed by the first monthly service fee on the
              date the Customer Loyalty Rewards Program is made publicly
              available, and then charged every month thereafter. See Fee
              Schedule published on Operator’s website https://savers.club/.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              3. DUTIES OF OPERATOR.
            </h3>
            <p className='text-base text-gray-700'>
              Operator agrees to establish and maintain a Customer Loyalty
              Rewards Program named after Participant’s business or
              organization. Operator warrants that it will create and operate a
              co-branded Participant customer engagement platform (hereinafter
              “App”) in Participants’ name, that may be offered to Participants’
              Customers through various channels, including but not limited to,
              mobile applications, website, email, newsletters, phone messaging,
              API gateway, and any other such communication channel that is
              currently available or may become available in the future.
              Operator further agrees to distribute compensations earned through
              the Customer Loyalty Rewards Program, as set forth in section 9
              and 10.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              4. DUTIES OF PARTICIPANT.
            </h3>
            <p className='text-base text-gray-700'>
              Participant agrees to represent its Customer Loyalty Rewards
              Program honestly and within its limitation and design. Participant
              shall comply with the following requirements to successfully
              enroll in the Customer Loyalty Rewards Program: (i) register for
              the Customer Loyalty Rewards Program, (ii) distribute Participant
              communications, emails, promotional, marketing, and informational
              content as instructed by Operator to Customers (iii) Participant’s
              communications to Customers shall be disseminated to Customer by
              email and all other means by which Participant normally
              communicates to Customer in its ordinary marketing activities; and
              (iv) perform co-public relations tasks with Operator, including
              but not limited to co-promotional, co-advertising and co-marketing
              initiatives, determined by Operator as necessary to support the
              Customer Loyalty Rewards Program. Participant shall provide to
              Operator’s satisfaction, upon request, reasonable proof of its
              compliance thereto with the marketing requirements above
              mentioned, and may from time to time, be required to submit to an
              audit review to accomplish the same.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              5. RELATIONSHIP OF OPERATOR AND PARTICIPANT.
            </h3>
            <p className='text-base text-gray-700'>
              Operator engages Participant as an independent contractor.
              Participant acknowledges and agrees that it retained Operator as
              an independent contractor, and neither party, nor any of their
              respective affiliates or customers, have the authority to bind the
              other. It is mutually agreed that this relationship shall not
              render Participant as an employee of, partner under company law
              with, agent of, or joint with, Operator for any purpose. Operator
              shall not be responsible for federal or state income, social
              security, or other such taxes as may be assessed against
              Participant as a result of fulfilling its duties pursuant to the
              scope identified in Section 1. Participant nor Participant’s
              employees or customers, shall not have any claim against Operator
              for vacation pay, sick leave, retirement benefits, social
              security, workers compensation, health or disability benefits,
              unemployment insurance benefits, or employee benefits or claims of
              any kind.
            </p>
            {/* New point */}

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>6. NO AGENCY.</h3>
            <p className='text-base text-gray-700'>
              Nothing in this Agreement shall be construed to create an agency
              or employment relationship between Operator and Participant or
              Participants’ Customers. Neither party shall be liable for any of
              the debts, accounts, obligations, or other liabilities or torts of
              the other party, or its agent or Participants.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              7. MARKETING AND PROMOTIONS INVOLVING OPERATOR.
            </h3>
            <p className='text-base text-gray-700'>
              Participant shall not use Operators’ name or any names owned or
              licensed by Operator or its affiliates as component of any domain
              name. Domain names held by Participant shall not contravene laws
              or the rights of third parties. Participant shall not use
              Operators’ name, marks, owned, licensed, or otherwise language
              protected by copyright, or its affiliates without Operator’s prior
              written consent.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              8. MARKETING AND PROMOTIONS BY PARTICIPANT.
            </h3>
            <p className='text-base text-gray-700'>
              Participant is authorized to promote and advertise the Customer
              Loyalty Rewards Program. Participant shall be responsible for all
              expenses it incurs. Promotion activities by Participant shall not
              violate any law and must comply with all applicable and regulatory
              requirements by Federal and State laws. Participant shall not
              engage in advertisements or promotions that may be harmful or
              offensive, including the use of defamatory, threatening or obscene
              language. Participant shall not use any merchant names or logos
              listed in Operator’s shopping mall or travel portal(s) in their
              promotional efforts, without prior written consent from Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              9. PARTICIPANT COMPENSATION STRUCTURE.
            </h3>
            <p className='text-base text-gray-700'>
              Participant shall receive compensation for the business
              transactions conducted through or driven by the Customer Loyalty
              Rewards Program by Customers according to the following: Up to ten
              percent (10%) of the Customer Loyalty Rewards Program amount
              actually received by a Customer enrolled on and through the
              Participant’s Customer Loyalty Rewards Program from each of the
              Customer’s qualifying purchases made from a merchant through the
              Customer Loyalty Rewards Program, provided, however, that for the
              purposes of the Participants Compensation hereunder, the Customer
              Loyalty Rewards Program amount for each qualifying Customer shall
              be capped at the cash amount that would have been earned by
              Customer excluding any additional cash back or rewards that may
              have been awarded for promotional purposes and through promotional
              campaigns managed directly by Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              10. DISTRIBUTION OF PARTICIPANT CONTRIBUTION.
            </h3>
            <p className='text-base text-gray-700'>
              All payments are subject to the receipt of Operator of the revenue
              on which the compensation payment to Participant is directly or
              indirectly based. In the event that any compensation payment has
              been paid to Participant based directly or indirectly on revenue
              that Operator initially receives but is not ultimately entitled
              to, such compensation payment shall be recoverable by Operator,
              and Participant will have the corresponding amount(s) deducted
              from their subsequent compensation payment owed until it is
              completely recovered. Instance where Operator may receive amounts
              not immediately payable to Participant is where a Customer returns
              a purchase from a merchant made through the Customer Loyalty
              Rewards Program. The minimum amount for which Operator will issue
              a compensation payment is the equivalent of Twenty-Five Dollars
              ($25.00). If Participant’s compensation payment does equal or
              exceed the equivalent of Twenty-Five Dollars ($25.00), Operator
              will accrue the compensation until the compensation payment equals
              or is the equivalent of Twenty-Five Dollars ($25.00). Compensation
              payments to Participant will be made in accordance with Operator’s
              policy and payment schedule as may be updated from time to time,
              and Participant will only be eligible to receive compensation
              payments for so long as Participant remains in compliance with the
              terms and requirements of this Agreement, Operator’s
              marketing-related policies, rules and requirements, and all
              applicable local, state, and federal laws, rules and regulations.
              Operator will make reasonable efforts to make available to
              Participant a running accounting of compensation earned by
              Participant, updated on a reasonable basis, in the Participant’s
              “back office” portal provided by Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>11. TERM</h3>
            {/* <p className="text-base text-gray-700"></p> */}

            <p className='text-base text-gray-700'>
              The initial term of this Agreement shall be for one (1) year after
              Operator provides Participant with notice of Operator’s acceptance
              of Participant into the Customer Loyalty Rewards Program and the
              Customer Loyalty Rewards Program is made publicly available.
              Thereafter, the term shall automatically renew for additional one
              (1) year periods of time subject to the right to terminate as set
              forth herein. If Operator or Participant wish to cease automatic
              one (1) year renewal, then either party shall provide written
              notice to the other party at least thirty (30) days prior to the
              expiration of the then-current annual term period of its election
              not to renew.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>12. TERMINATION</h3>
            <p className='text-base text-gray-700'>
              Operator shall have the right to terminate this Agreement at any
              time, with or without notice, should Participant commit a breach
              thereof or fail to comply with those terms published by Operator
              and which may be amended from time to time at the sole and
              exclusive discretion of Operator, or ceases to do business. After
              the initial term, Operator shall have the right to terminate this
              Agreement at any time and for any or no reason by giving sixty
              (60) days prior written notice to Participant. Participant shall
              have the right to terminate this agreement for any reason by
              providing Operator with ninety (90) days prior written notice to
              Operator. In any event that termination is not caused due to a
              breach of Operator or effectuated by Operator due to a breach of
              Participant, all fees owed and owing to Operator according to
              Section 2 of the Agreement, if any, shall be immediately due.
              Notwithstanding anything to the contrary, fees that are paid to
              Operator under this Agreement are non-refundable. Within thirty
              (30) days following any termination by either Operator or
              Participant, or within ten (10) days following written request by
              Participant, Operator will make available to Participant a final
              accounting of compensation earned by Participant hereunder.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              13. CESSATION OF BUSINESS.
            </h3>
            <p className='text-base text-gray-700'>
              Should Operator cease its daily operations or sell all of its
              assets or find itself financially unable to continue the Customer
              Loyalty Rewards Program, Operator may upon such occurrence
              terminate this Agreement by providing Participant written
              notification of the same.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>14. CONFIDENTIALITY</h3>
            <p className='text-base text-gray-700'>
              Participant acknowledges that all information obtained during the
              course of this Agreement with Operator and associated with the
              Customer Loyalty Rewards Program is confidential and the parties
              agree that the information falls within the definition of trade
              secrets as set forth under Federal Law and the particular State in
              which Participant makes sales. The confidential information
              provided to Participant in the operation and promotion of the
              Customer Loyalty Rewards Program includes Participant’s knowledge
              gained in the operation of the Customer Loyalty Rewards Program.{' '}
              <br />
              <br /> It is recognized and hereby acknowledged by the parties
              hereto that a breach by the Participant of any of the Covenants
              contained herein may cause irreparable harm and damage to the
              Operator, the monetary amount of which may be virtually impossible
              to ascertain. As a result, Participant recognizes and hereby
              acknowledges that the Operator may be entitled to an injunction
              from any court of competent jurisdiction enjoining and restraining
              any violation of any or all of the Covenants contained hereof by
              the Participant, or Participant’s associates, employees, partners,
              or agents, directly or indirectly, and that such right to
              injunction may be cumulative and in addition to whatever other
              legal or equitable remedies the Operator may possess. <br />{' '}
              <br /> Participant agrees that the confidential information
              acquired during the term is the sole and exclusive property of
              Operator. Participant agrees that as a prerequisite to associate
              with Operator, Participant shall not during the term of this
              Agreement, and for a period of two (2) years thereafter, directly
              or indirectly use, disclose, or disseminate to any third party the
              confidential information gained or obtained during the course of
              Participant’s association with Operator. Participant acknowledges
              and agrees that divulging said information shall be a breach of
              this Agreement and that Operator can seek injunctive relief to
              ensure that this confidential information is not disclosed to
              third parties. The parties agree that in the event of a breach of
              this portion of this Agreement by Participant the potential
              financial damage resulting to Operator will be difficult to
              determine on a case-by-case basis and, therefore, Participant
              agrees that each violation of this portion of the Agreement shall
              subject Participant to reasonable liquidated damages in the amount
              of $10,000.00 for each occurrence, This provision of the Agreement
              shall survive termination of Participant’s association with
              Operator. <br />
              <br />
              Participant acknowledges that during their engagement they may
              have access to, and become acquainted with, various trade secrets,
              inventions, innovations, processes, information, records and
              specifications owned or licensed by Operator and or used by
              Operator in connection with the operation of its business
              including, but not limited to Operator’s business, services,
              products, methods, customer lists, accounts, and procedures.
              Participant agrees that they will not disclose any of the
              aforesaid, directly or indirectly, or use any of them in any
              manner, either during the term of the Agreement or any time
              thereafter, except as required and approved by written consent of
              Operator in the course of their agreement with Operator. <br />
              <br />
              All Memorandum, Notes, Documents, Business Plans, Customer Lists,
              Computer Programs, Recipes, and Any Other Record of Any Kind Made
              or Complied, in Whole or in Part, by Participant or made available
              to the Participant during the course of the Participant’s
              involvement with the establishment of the Customer Loyalty Rewards
              Program, which constitutes a part of the Confidential Information,
              and any and all copies thereof, shall be delivered to Operator
              upon the termination of Participant’s involvement with Operator
              for whatever reason or at any time upon request.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              15. INTELLECTUAL PROPERTY RIGHTS.
            </h3>
            <p className='text-base text-gray-700'>
              Participant agrees that all material, documentation, deliverables,
              and other tangible expressions of information including but not
              limited to software programs and software documentation, billing
              programs, designs, technical data, formulae, and processes whether
              in final production or draft which result from any work performed
              by Participant providing services under this Agreement, shall
              belong exclusively to Operator. Participant agrees that the
              confidential information acquired during the term is the sole and
              exclusive property of Operator. Participant agrees that as a
              prerequisite to obtaining employment from Operator, Participant
              shall not during the term of this employment, and for a period of
              two (2) years thereafter, directly or indirectly use, disclose, or
              disseminate to any third party the confidential information gained
              or obtained during the course of Participant’s association with
              Operator. Participant acknowledges and agrees that divulging said
              information shall be a breach of this Agreement and that Operator
              can seek injunctive relief to ensure that this confidential
              information is not disclosed to third parties. <br />
              <br />
              The parties agree that in the event of a breach of this portion of
              this Agreement by Participant the potential financial damage
              resulting to Operator will be difficult to determine on a
              case-by-case basis and, therefore, Participant agrees that each
              violation of this portion of the Agreement shall subject
              Participant to reasonable liquidated damages in the amount of
              $10,000.00 for each occurrence, This provision of the Agreement
              shall survive termination of Participant’s association with
              Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              16. COVENANT NOT TO SOLICIT EMPLOYEES.
            </h3>
            <p className='text-base text-gray-700'>
              During the Term of this Agreement including any extension thereof,
              and for a period of two (2) years after the termination or
              expiration of this Agreement for any reason, Participant agrees
              not to hire personnel of Operator, or its affiliates, for any
              business in which Participant may become associated. <br />
              <br />
              It is the intention of these provisions that any person or entity
              with any legal or beneficial interest in or traceable to, down or
              through the Participant be bound by the provisions of this
              Covenant.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>17. DAMAGES</h3>
            <p className='text-base text-gray-700'>
              Nothing contained herein shall be construed to prevent the
              Operator or the Participant from seeking and recovering from the
              other damages sustained by either or both as a result of its
              breach or violation of any term or provision of this Agreement
              other than as set forth herein. In the event that either party
              hereto brings suit for the collection of any damages resulting
              from, or the injunction of any action constituting, a breach or
              violation of any of the terms of provisions of this Agreement,
              then the party found to have damaged the other and/or defined to
              be the prevailing party in said litigation shall pay all
              reasonable court costs and attorney’s fees of the damaged party.
              This provision of the Agreement shall survive termination of
              Participant’s association with Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>18. MODIFICATION</h3>
            <p className='text-base text-gray-700'>
              Participant understands and agrees that Operator will notify
              Participant of all such future acts, processes and information as
              may be reasonable required from Participant to carry out fully and
              to effectuate the provisions of this contractual relationship of
              the parties, and any changes, waivers, discharges, or terminations
              to this Agreement which Operator in its discretion may deem
              necessary, by submitting any such change in writing to
              Participant. Notwithstanding the foregoing, notice of any such
              future acts, changes, waivers, discharges, or terminations may be
              provided by Operator publishing updated terms online through
              Operator’s website.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              19. GOVERNING LAW AND INTERPRETATION
            </h3>
            <p className='text-base text-gray-700'>
              This Agreement shall be governed under and shall be enforceable
              under the laws of the State of Delaware without regard to the
              conflict of law provision thereof.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              20. ARBITRATION OF DISPUTES.
            </h3>
            <p className='text-base text-gray-700'>
              Operator and Participant agree that the exclusive remedy for the
              adjudication of any and all disputes arising out of this Agreement
              and their association with each other, including, but not limited
              to, unpaid compensation payments under the Customer Loyalty
              Rewards Program, or breach of contract at the Federal or State
              Court level, shall be maintained through Binding Arbitration. In
              doing so, the Parties hereto hereby waive any and all right to a
              trial by jury in any action based hereon on arising hereunder, or
              under the Customer Loyalty Rewards Program, whether said action be
              a claim, counterclaim, or otherwise. The waiver made herein shall
              extend to actions in state, federal bankruptcy, and any forum
              whatsoever. The situs for said Arbitration shall be in Miami,
              Florida, under the auspices of the Commercial Arbitration Rules of
              the American Arbitration Association. Discovery shall be permitted
              as set forth under the Rules of Federal Procedure without
              limitation. Any decision or award as a result of the Arbitration
              proceeding shall be in writing and shall provide an explanation
              for all conclusions of law and fact. The Arbitrator shall include
              the assessment of costs, expenses, and attorney’s fees if provided
              for by the applicable Federal Code Section or State Statute or by
              contract. Notwithstanding the foregoing, each party shall
              initially bear their own attorney’s fees and costs of the
              proceedings. The Arbitration award may be confirmed by a Court of
              competent jurisdiction.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              21. ADEQUATE RIGHTS; LIMITED LICENSE BY PARTNER:
            </h3>
            <p className='text-base text-gray-700'>
              Participant warrants to Operator that: (a) Participant owns all
              rights, titles, and interests in and to Participant’s tradename(s)
              and trademark(s) provided to Operator for the purposes of setting
              up and maintaining the Customer Loyalty Rewards Program; (b)
              Participant has all rights in Participant’s tradename(s) and
              trademark(s) provided to Operator necessary to grant the rights
              contemplated by this Agreement and the Customer Loyalty Rewards
              Program; and (c) the individual accepting on behalf of Participant
              is an authorized signatory of Participant and has been provided by
              Participant all authority and rights necessary to bind Participant
              hereto. <br />
              <br />
              Participant hereby: (i) grants to Operator and Operator’s
              affiliates the limited right and license to use and display
              Participant’s tradename(s) and trademark(s) provided to Operator
              for the purposes of setting up and maintaining the Customer
              Loyalty Rewards Program as well as for promotional use and
              marketing purposes; (ii) shall indemnify and hold harmless
              Operator and Operator’s affiliates use of Participant’s
              tradename(s) and trademark(s) as such use is contemplated by this
              Agreement and the Customer Loyalty Rewards Program; and (iii)
              hereby waives and releases Operator and Operator’s affiliates from
              any liability in relation to the Customer Loyalty Rewards Program
              or Operator’s affiliates use of such Participants tradename(s) and
              trademark(s) as such use is contemplated by this Agreement and the
              Customer Loyalty Rewards Program.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              22. LIMITED LICENSE BY OPERATOR
            </h3>
            <p className='text-base text-gray-700'>
              As between the parties to this Agreement, Operator and/or its
              affiliates or licensors, own and reserve all rights, titles, and
              interest in and to the Customer Loyalty Rewards Program and all
              services, components, and offerings contemplated thereby,
              including but not limited to the Customer Loyalty Rewards Program.
              Operator grants Participant a limited, revocable, non-exclusive,
              non- sublicensable, non-transferrable license during the term to
              access, use and take part in the Customer Loyalty Rewards Program
              solely as expressly provided by, and in accordance with this
              Agreement. <br />
              <br />
              Participant obtains no rights under this Agreement, from Operator,
              or its affiliates or licensors to the Customer Loyalty Rewards
              Program or any aspects thereof, including any related intellectual
              property rights. Participant may not, and may not attempt to, (a)
              modify, alter, tamper, repair or otherwise create derivative works
              of any services or components thereof including under the Customer
              Loyalty Rewards Program; or (b) reverse engineer, disassemble, or
              decompile any services or components thereof included under the
              Customer Loyalty Rewards Program or apply any other process or
              procedure to deprive the source code of any software included in
              or utilized as a part of such services. <br />
              <br />
              All licenses and permissions granted to Participant in this
              Agreement are conditional on Participant’s continued compliance
              with this Agreement. The parties agree that in the event of a
              breach of this portion of this Agreement by Participant the
              potential financial damage resulting to Operator will be difficult
              to determine on a case-by-case basis and, therefore, Participant
              agrees that each violation of this portion of the Agreement shall
              subject Participant to reasonable liquidated damages in the amount
              of $1,000.00 for each occurrence, This provision of the Agreement
              shall survive termination of Participant’s association with
              Operator.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              23. DISCLAIMER OF WARRANTIES.
            </h3>
            <p className='text-base text-gray-700'>
              The Customer Loyalty Rewards Program and any and all additional
              services offered pursuant thereto are provided “AS IS.” Operator
              makes no representation or warranties of any kind, whether express
              or implied, statutory or otherwise, regarding the Customer Loyalty
              Rewards Program or any services offered pursuant thereto or any
              third-party content on any Participant program, including any
              warranty that the Customer Loyalty Rewards Program or any services
              offered pursuant thereto or any third-party content will be
              uninterrupted, error free, or free of harmful components, or that
              any content, including the Customer Loyalty Rewards Program
              content or third-party content will be secure or not otherwise
              lost or damaged. Except to the extent prohibited by law, Operator
              disclaim all warranties, including any implied warranties of
              Merchantability and/or Fitness for a Particular Purpose.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              24. CLASS ACTION WAIVER.
            </h3>
            <p className='text-base text-gray-700'>
              Unless required by applicable law, Operator and Participant agree
              that neither shall have the right or entitlement to assert a class
              action or make representative action claims against the other in
              Arbitration or otherwise. This waiver is irrevocable for any
              reason whatsoever.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              25. NO DELEGATION OR ASSIGNMENT.
            </h3>
            <p className='text-base text-gray-700'>
              The Participant shall not delegate or assign their obligations
              under this Agreement to any other person, business, or entity.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>26. NOTICES</h3>
            <p className='text-base text-gray-700'>
              Any notice required or permitted to be given under this Agreement
              shall be in writing and shall be deemed to have been duly given
              when delivered by email, by hand or when deposited in the United
              States mail, by registered or certified mail, return receipt
              requested, postage prepaid, as follows: <br />
              If to Operator: <br />
              Savers Club <br />
              1111B. S. Governors Ave, Unit 21598 <br />
              Dover, Delaware 19904 <br />
              Attention: Legal Counsel <br />
              Email: legal@savers.club
              <br />
              <br />
              Or to such other addresses as either party hereto may from time to
              time give notice of to the other. Notwithstanding the foregoing,
              (a) notices of any modification to the Customer Loyalty Rewards
              Program or the Terms may be posted online by Operator, either in
              the form of updated Terms or otherwise, and such shall serve as
              valid notice with regard to such, and (b) other notices that may
              be provided by Operator may be posted on a Participant’s “back
              office” portal provided by Operator, and such shall serve as valid
              notice with regard to such.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              27. BENEFITS; BINDING EFFECTS.
            </h3>
            <p className='text-base text-gray-700'>
              This Agreement shall be for the benefit of and binding upon the
              parties hereto and their respective heirs, personal
              representatives, legal representatives, successor and, where
              applicable, assigns.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>
              28. ADVICE OF COUNSEL.
            </h3>
            <p className='text-base text-gray-700'>
              The Participant has been informed that they have the right to seek
              independent legal advice prior to the execution of this Agreement
              and that Operator provided no legal advice to Participant
              regarding the terms and conditions of this Agreement.
            </p>

            {/* New point */}
            <h3 className='font-bold mb-2 py-4 pt-10'>29. SEVERABILITY</h3>
            <p className='text-base text-gray-700'>
              The invalidity of one or more of the words, phrases, sentences,
              clauses or sections contained in this Agreement shall not affect
              the enforceability of the remaining portions of this Agreement or
              any part hereof, all of which are inserted conditionally on their
              being valid in law, and, in the event that anyone or more of the
              words, phrase, sentences, clauses or sections contained in this
              Agreement shall be declared invalid, this Agreement shall be
              construed as if such invalid word or words, phrase or phrases,
              sentence or sentences, clause or clauses, or section or sections,
              have not been inserted.
            </p>
            <h3 className='font-bold mb-2 py-4 pt-10'>30. GENDER & NUMBER.</h3>
            <p className='text-base text-gray-700'>
              Whenever the context shall so require, all words herein in any
              gender shall be deemed to include the masculine, feminine or
              neutral gender; all singular words shall include the plural and
              all plural shall include the singular.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>31. LANGUAGE NEUTRAL.</h3>
            <p className='text-base text-gray-700'>
              The parties agree that in the enforcement and interpretation of
              this Agreement that the Courts shall not be read or interpreting
              more strictly against one party. The parties agree that this
              Agreement shall be enforced equally against all parties hereto
              notwithstanding the fact that the Agreement may have been drafted
              by one of the parties and not by all of the parties.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>32. WAIVERS.</h3>
            <p className='text-base text-gray-700'>
              The waiver by either party hereto of a breach of a violation of
              any provisions of this Agreement shall not operate to nor be
              construed as a waiver of any subsequent breach or violation.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>33. SECTION HEADINGS.</h3>
            <p className='text-base text-gray-700'>
              The section headings contained in this Customer Loyalty Rewards
              Program Agreement are for reference purposes only and shall not
              affect in any way the meaning or interpretation of this Agreement.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>
              34. NO THIRD-PARTY BENEFICIARIES.
            </h3>
            <p className='text-base text-gray-700'>
              This Agreement does not create any third-party beneficiary right
              in any person or entity that is not a party to this Agreement.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>35. COUNTERPARTS.</h3>
            <p className='text-base text-gray-700'>
              This Agreement shall be executed in any number of counterparts,
              all executed counterparts constituting one Agreement.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>
              36. SURVIVAL OF TERMS.
            </h3>
            <p className='text-base text-gray-700'>
              The parties agree that the terms and conditions of this Agreement
              as it relates to confidential information and non-competition
              shall survive the termination of this Agreement in order to
              protect the interests of Operator. Participant has reviewed these
              terms and agree with Operator that this is reasonable. The parties
              agree that if any such term of the Agreement which is to survive
              the association of the Participant is held to be unenforceable,
              the parties agree that it is the desire of the parties that the
              Court shall substitute a reasonable judicially enforceable
              limitation on the conduct of the Participant in substitution of
              any limitation deemed unenforceable and that as so modified, the
              covenants shall be fully enforceable. It is the intent of the
              parties to this Agreement that the Court recognize that the
              parties hereto desire the foregoing covenants be imposed and
              maintained to the greatest extent possible.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>37. ENTIRE AGREEMENT.</h3>
            <p className='text-base text-gray-700'>
              This Customer Loyalty Rewards Program Agreement constitutes the
              entire Agreement between the parties hereto and supersedes all
              prior agreements, understandings and arrangements, both oral and
              written, between the parties hereto with respect to the subject
              matter hereof.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>
              38. ACKNOWLEDGMENT OF ARBITRATION.
            </h3>
            <p className='text-base text-gray-700'>
              This Agreement contains an Arbitration provision as set forth in
              section 20, which is hereby acknowledged as a material term of
              said Agreement.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>39. INDEMNIFICATION.</h3>
            <p className='text-base text-gray-700'>
              The parties agree to indemnify each other and hold each other
              harmless and to defend each other as the case may be from any and
              all liability or loss; against all claims of any nature arising
              from each other’s performance and/or nonperformance of the terms
              and agreements of this Agreement, including, but not limited to,
              accidents that occur on site. The indemnification in question
              shall include, but not be limited to, damages, expenses,
              attorney’s fees and costs incurred by the other party in the
              investigation of or defense against any claim against it as a
              result of the acts of the other party.
            </p>

            <h3 className='font-bold mb-2 py-4 pt-10'>
              40. ACCEPTANCE OF TERMS.
            </h3>
            <p className='text-base text-gray-700'>
              By accepting this Agreement, the Participant agrees and warrants
              that they have thoroughly read and completely understand all the
              terms, conditions, duties, and responsibilities contained herein
              and consent to be bound by the same.
            </p>
          </div>
        </section>

        <hr className='my-12' />
        <section id='privacyPolicy'>
          <p className='text-base font-[400] my-8'>
            Current as of August 27, 2024
          </p>
          <h2 className='text-[1.25rem] leading-[2rem] md:text-[40px] text-[#2D2D2D] md:leading-[40px] font-[400] mb-8'>
            Privacy Policy
          </h2>
          <p className='text-base text-gray-700'>
            Your privacy is important to us, and we want you to feel comfortable
            using the MAD REWARDS Inc. Savers Club Partner Program (“Savers
            Club”, “MAD Rewards”, “Company”, “we”, “us” or “our” as the context
            dictates) as it is described and outlined in the Partner Program
            Terms. Given this, we have created this Partner Program Privacy
            Policy (the "Program Privacy Policy") which applies to any purchaser
            of the Partner Program and shall be read in conjunction with our
            Partner Program Terms.
          </p>{' '}
          <br />
          <p className='text-base text-gray-700'>
            We are committed to protecting your privacy. This is a secure
            business-to-business site, which is protected using advanced
            encryption and other security technology. The secure server software
            (SSL) encrypts all information before it is submitted to us. The
            information you provide will be kept confidential and only used to
            fulfill your relationship with us.
          </p>{' '}
          <br />
          <p className='text-base text-gray-700'>
            This Program Privacy Policy explains the data protection and usage
            practices of MAD Rewards when you purchase the Partner Program
            services and applications, to which this policy is linked.
          </p>{' '}
          <br />
          <div className='term'>
            <h3 className='font-bold mb-2 py-4 pt-10'>1. General</h3>
            <p className='text-base text-gray-700'>
              To provide you with the benefits of the Partner Program, we need
              to collect information, which may also include your personal
              information or data, as defined under the applicable law. Please
              be aware that your participation and use of our Partner Program
              may also result in the collection of certain information on
              account of technologies detailed in our Partner Program Terms and
              Program Privacy Policy.
            </p>
            <h3 className='font-bold mb-2 py-4 pt-10'>
              2. INFORMATION WE COLLECT THROUGH THE PARTNER PROGRAM
            </h3>
            <p className='text-base text-gray-700'>
              We collect and use several types of information from and about you
              for the Partner Program, including:
            </p>
            <p className='text-base text-gray-700'>
              <span className='font-bold'>Personal information</span>, that we
              can reasonably use to directly or indirectly identify you, such as
              your name, email address, phone number, Internet protocol (IP)
              address used to connect your computer to the Internet,
              login/username, or other similar identifier, and any other
              identifier we may use to contact you ("
              <span className='font-bold'>personal information</span>"). {' '}
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              We provide an opportunity for you to unsubscribe or opt-out of
              specific categories of communication on an ongoing basis by using
              the unsubscribe mechanism at the bottom of our e-mailsor by
              e-mailing to support@savers.club{' '}
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              <span className='font-bold'>Non-personal information</span>,that
              does not directly or indirectly reveal your identity or directly
              relate to an identifiable individual, such as statistical or
              aggregated information. Statistical or aggregated data does not
              directly identify a specific person, but we may derive
              non-personal statistical or aggregated data from personal
              information. For example, we may aggregate personal information to
              calculate the percentage of people accessing a specific feature of
              the Partner Program.
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              <span className='font-bold'>Technical information,</span>including
              your login information, browser/APP type and version, time zone
              setting, browser/APP plug-in types and versions, device operating
              system and platform, information about your internet connection,
              the equipment you use to access the Partner Program, and usage
              details. We use cookies where applicable to collect and store a
              user’s Partner Program preferences, information for allowing
              Partner Program log-ins, and potential data to be used for
              advertising purposes.
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              <span className='font-bold'>
                Non-personal details about your interactions with the Partner
                Program,
              </span>
              including the full Uniform Resource Locators (URLs), clickstream
              to, through, and from our websites (including date and time),
              products you viewed or searched for, page response times, download
              errors, length of visits to certain pages, page interaction
              information (such as scrolling, clicks, and mouse-overs), methods
              used to browse away from the page, or any phone number used to
              call our customer service number.
            </p>{' '}
            <br />
            <h3 className='font-bold mb-2 py-4 pt-10'>
              3. HOW WE USE YOUR INFORMATION
            </h3>
            <p className='text-base text-gray-700'>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <ul className='list-disc pl-[3.25rem] space-y-3'>
              <li className='text-base text-gray-700'>
                to present our Partner Program and its contents to you;
              </li>
              <li className='text-gray-700'>
                to fulfill the purposes for which you provided the information
                or that were described when it was collected, or any other
                purpose for which you provide it;
              </li>
              <li className='text-gray-700'>
                to provide you with notices about your account/subscription,
                including expiration and renewal notices;
              </li>
              <li className='text-gray-700'>
                to carry out our obligations and enforce our rights arising from
                any contracts with you, including for billing and collection or
                to comply with legal requirements;
              </li>
              <li className='text-gray-700'>
                to improve our Partner Program, products or services, marketing,
                or customer relationships and experiences;
              </li>
              <li className='text-gray-700'>
                in any other way we may describe when you provide the
                information; or
              </li>
              <li className='text-gray-700'>
                for any other purpose with your consent.
              </li>
            </ul>
            <h3 className='font-bold mb-2 py-4 pt-10'>
              4. PERMISSION FOR COLLECTION OF INFORMATION
            </h3>
            <p className='text-base text-gray-700'>
              By using the Partner Program, you are hereby consenting to the
              collection of your information by us. The information in some
              cases may include personal and sensitive personal information. In
              case of collection of sensitive personal information, you will be
              required to confirm your acceptance of this Program Privacy
              Policy. By accepting the present Program Privacy Policy you are
              also representing that you are eighteen (18) years of age or
              above.
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              Please be aware that, if you do not allow us to collect
              information from you, we may not be able to deliver certain
              products and services to you, and some of our services may not be
              able to take account of your interests and preferences. By
              providing your information to us you are consenting to the use of
              such information by us in accordance with this Program Privacy
              Policy.
            </p>{' '}
            <br />
            <h3 className='font-bold mb-2 py-4 pt-10'>
              5. SHARING OF INFORMATION COLLECTED FOR THE PARTNER PROGRAM
            </h3>
            <p className='text-base text-gray-700'>
              We may disclose aggregated information about the use of the
              Partner Program, and information that does not identify any
              individual, without restriction.
            </p>{' '}
            <br />
            <p className='text-base text-gray-700'>
              We may disclose personal information that we collect, or you
              provide as described in this privacy policy:
              <br />
              <ul className='list-disc pl-[3.25rem] space-y-3 mt-2'>
                <li className='text-gray-700'>
                  to our subsidiaries and affiliates;{' '}
                </li>
                <li className='text-gray-700'>
                  in accordance with applicable law, to a buyer or other
                  successor in the event of a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of the Company’s assets, whether as a
                  going concern or as part of bankruptcy, liquidation, or
                  similar proceeding, in which personal information held by our
                  vendors about our customers and users is among the assets
                  transferred;{' '}
                </li>
                <li className='text-gray-700'>
                  to contractors, service providers, and other third parties we
                  use to support our business (such as analytics and search
                  engine providers that assist us with the improvement and
                  optimization of our websites) and who are contractually
                  obligated to keep personal information confidential, use it
                  only for the purposes for which we disclose it to them, and to
                  process the personal information with the same standards set
                  out in this policy;{' '}
                </li>
                <li className='text-gray-700'>
                  to fulfill the purpose for which you provide it. For example,
                  if you give us an email address to use the "refer a friend"
                  feature of our websites, we will transmit the contents of that
                  email and your email address to the recipients;
                </li>
                <li className='text-gray-700'>
                  for any other purpose disclosed by us when you provide the
                  information;{' '}
                </li>
                <li className='text-gray-700'>with your consent;</li>
              </ul>
            </p>
            <p className='text-base text-gray-700'>
              We may also disclose your personal information:
              <br />
              <ul className='list-disc pl-[3.25rem] space-y-3 mt-2'>
                <li className='text-gray-700'>
                  to comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request,
                  in accordance with applicable law;{' '}
                </li>
                <li className='text-gray-700'>
                  to enforce or apply the Partner Program Terms and other
                  agreements, including for billing and collection purposes;
                  and/or
                </li>
                <li className='text-gray-700'>
                  if we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of us, our customers,
                  or others. This includes exchanging information with other
                  companies and organizations for the purposes of fraud
                  protection and credit risk reduction.{' '}
                </li>
              </ul>
            </p>
            <h3 className='font-bold mb-2 py-4 pt-10'>
              6. Personal Information from Users under 18 years of age
            </h3>
            <p className='text-base text-gray-700'>
              We do not knowingly collect personal information from users under
              18 years of age. If you believe that such a user has provided
              personal information to us, please contact us via
              support@savers.club, so that we can identify and delete that
              user's personal information.
            </p>
            <h3 className='font-bold mb-2 py-4 pt-10'>
              7. Changes to Program Privacy Policy
            </h3>
            <p className='text-base text-gray-700'>
              This Program Privacy Policy is current as of the effective date
              set forth above. We reserve the right to change this Program
              Privacy Policy from time to time consistent with applicable
              privacy laws and principles. If we make changes to this Program
              Privacy Policy we will notify you by revising the date at the top
              of this Program Privacy Policy and provide you with additional
              notice (such as adding a statement to the homepages of our
              services or sending you an email notification to obtain your
              consent).
            </p>
            <h3 className='font-bold mb-2 py-4 pt-10'>
              8. QUESTIONS OR COMPLAINTS
            </h3>
            <p className='text-base text-gray-700'>
              If you have any complaints or grievances regarding the information
              shared with us, please feel free to contact us at
              support@savers.club{' '}
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicyPage;
