import React from 'react';
import { filterClassNames } from '../../utils/filterClassnames';
interface Props {
  className?: string;
  children: React.ReactNode;
}

function Container({ className, children }: Props) {
  return (
    <div
      className={filterClassNames(
        `bg-white pb-5 rounded-lg px-2.5 ${className}`
      )}
    >
      {children}
    </div>
  );
}

export default Container;
