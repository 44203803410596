import React, { useState } from 'react';

interface StartupPlanFormProps {
  paymentSelected: boolean;
  setPaymentSelected: React.Dispatch<React.SetStateAction<boolean>>;
}
const StartupPlanForm: React.FC<StartupPlanFormProps> = ({
  paymentSelected,
  setPaymentSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          {/* Modal Content */}
          <div className='bg-white p-6 rounded-md w-[90%] md:w-[50%] max-w-[38rem] relative'>
            {/* Close Button */}
            <button
              onClick={closeModal}
              className='absolute top-4 right-4 text-gray-400 text-[2.5rem]'
            >
              &times;
            </button>

            {/* Modal Text Content */}
            <div className='text-center md:m-8 font-Montserrat'>
              <p className='text-black text-[18px] leading-[28px] mb-6 font-medium'>
                You will be charged the following monthly fees based on the
                number of enrolled credit or debit cards:
              </p>
              <p className='text-black text-[18px] leading-[34px] font-semibold mt-[2.5rem]'>
                $250 for 1 to 1,000 cards <br />
                $500 for 1,001 to 2,000 cards <br />
                $750 for 2,001 to 3,000 cards
              </p>
              <p className='text-black text-[16px] leading-[24px] font-medium mt-[2.5rem]'>
                And the fee will continue to increase accordingly for larger
                quantities.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className='md:container justify-center'>
        <div className='bg-white shadow-md rounded-lg w-full'>
          <div className='mb-8'>
            <div>
              <h2 className='text-white bg-[#1133BB] p-4 px-8 rounded-t-lg text-lg font-semibold'>
                Startup Plan
              </h2>
              <div className='p-4 md:p-10 border border-gray-200 rounded-b-lg'>
                {/* Radio Option 1: $4,995 */}
                <div className='flex flex-col md:flex-row justify-between md:items-center mb-6 gap-4'>
                  <label className='flex  md:items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      name='startup-plan'
                      value='$4,995'
                      onChange={({ target: { checked } }) =>
                        setPaymentSelected(checked)
                      }
                      className='mt-2 md:mt-0 ml-2 md:ml-auto md:mr-4 w-4 h-4 md:w-5 md:h-5 scale-150'
                    />
                  </label>

                  {/* Card Wrapper for Section 1 and Section 2 */}
                  <div className='flex w-full flex-col md:flex-row p-4 gap-4 border border-gray-300 rounded-lg shadow-sm'>
                    {/* Section 1 */}
                    <div className='flex flex-col w-full md:w-1/2 p-4 gap-4'>
                      <div className='flex'>
                        <span className='text-4xl font-bold'>$4,995</span>
                      </div>
                      <div className='flex md:h-[7rem]'>
                        <p
                          className='text-base mt-2'
                          style={{ color: 'black' }}
                        >
                          One-time fee for setup and configuration
                        </p>
                      </div>
                    </div>

                    {/* Section 2 */}
                    <div className='flex flex-col w-full md:w-1/2 p-4 gap-4 border-gray-300'>
                      <div className='flex'>
                        <span className='text-4xl font-bold'>$250</span>
                      </div>

                      <div className='flex md:h-[7rem] flex-col'>
                        <p
                          className='text-base mt-2'
                          style={{ color: 'black' }}
                        >
                          Monthly service fee
                          <br />
                          For up to 1,000 enrolled cards
                        </p>
                        <span
                          className='underline text-[#0A0A0A4D] pt-4 cursor-pointer w-fit'
                          onClick={openModal}
                        >
                          Price Details
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Select Button */}
                {/* <div className='flex justify-center'>
          <button className='bg-primary text-white px-8 py-3 rounded-lg'>
            Select
          </button>
        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupPlanForm;
