import axios from 'axios';

export const downloadImage = (img: string, fileName: string = '') => {
  const link = document.createElement('a');
  link.href = img;

  if (img.startsWith('data:')) {
    link.download = fileName; // Use provided or default filename
  } else {
    // Extract filename if it's a standard URL
    link.download = img.substring(img.lastIndexOf('/') + 1).split('.')[0];
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadImageHTTPS = async (url: string): Promise<void> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
      },
    });
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', 'qr-code.png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Failed to download image:', error);
  }
};

// // Usage
// const imageUrl =
//   'https://s3.amazonaws.com/stageassets.savers.club/partner-assets/2/MOBILE-APP-LINKS+10-14-2024.png';
// downloadImage(imageUrl);
