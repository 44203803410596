import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ForgotPassword, UpdatePassword } from '../../features/authSlice';

interface FormInput {
  email?: string;
  password?: string;
  confirmPassword?: string;
}

const ForgotPasswordForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const h = searchParams.get('h'); 
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [modalMessage, setModalMessage] = useState<string | null>(null); 
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  useEffect(() => {
    if (h) {
      setIsResetPassword(true);
    }
  }, [h]);

  const onForgotPasswordSubmit: SubmitHandler<FormInput> = async data => {
    try {
      const { email } = data;
      const result: any | undefined = await ForgotPassword(email as any);

      if (result?.status === 200) {
        // Show modal on success
        setModalMessage('We have sent you an email to reset your password.');
      } else if (result?.response?.status === 404) {
        setApiErrorMessage(result.response.data.message);
      }
    } catch (error: any) {
      console.log(error);
      setApiErrorMessage(error.response.data.message);
    }
  };

  const onResetPasswordSubmit: SubmitHandler<FormInput> = async data => {
    const passwordPattern = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

    if (data.password !== data.confirmPassword) {
      setApiErrorMessage('Passwords don\'t match')
      return;
    }

    if (!passwordPattern.test(data.password as any)) {
      setApiErrorMessage(
        'Password must be at least 8 characters long, include at least one uppercase letter and one special character'
      );
      return;
    }

    const payload = {
      encryptedText: h || '',
      password: data.password,
    };

    try {
      const result: any | undefined = await UpdatePassword(payload);
      if (result?.status === 200) {
        setModalMessage('Your password has been updated successfully.');
      } else if (result?.response?.status === 404) {
        setApiErrorMessage(result.response.data.message);
      }
    } catch (error: any) {
      console.log(error);
      setApiErrorMessage('An error occurred. Please try again.');
    }
  };

  const handleModalClose = () => {
    setModalMessage(null);
    navigate('/signin');
  };

  return (
    <section>
      <div className='flex flex-col h-[75.5vh] items-center bg-primaryBg-50 justify-center py-8 mx-auto lg:py-0'>
        <div className='w-full p-6 rounded-lg md:mt-0 sm:max-w-md sm:p-8'>
          {!isResetPassword ? (
            <>
              <h1 className='mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
                Forgot your password?
              </h1>
              <p className='font-light text-gray-500'>
                Don't fret! Just type in your email and we will send you a code to reset your password!
              </p>
              <form className='mt-4 space-y-4 lg:mt-5 md:space-y-5' onSubmit={handleSubmit(onForgotPasswordSubmit)}>
                <div>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-gray-900'
                  >
                    Your email
                  </label>
                  <input
                    type='email'
                    id='email'
                    {...register('email', { required: 'Email is required' })}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary block w-full p-2.5 ${
                      errors.email && 'border-red-500'
                    }`}
                    placeholder='name@company.com'
                  />
                  {errors.email && (
                    <p className='text-[#b50000] mt-3 font-medium text-sm'>{errors.email.message}</p>
                  )}
                  {apiErrorMessage && (
                    <p className='text-[#b50000] mt-3 font-medium text-sm'>{apiErrorMessage}</p>
                  )}
                </div>
                <button
                  type='submit'
                  className='w-full text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center'
                >
                  Reset password
                </button>
              </form>
            </>
          ) : (
            <>
              <h1 className='mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
                Reset your password
              </h1>
              <form className='mt-4 space-y-4 lg:mt-5 md:space-y-5' onSubmit={handleSubmit(onResetPasswordSubmit)}>
                <div>
                  <label
                    htmlFor='password'
                    className='block mb-2 text-sm font-medium text-gray-900'
                  >
                    New Password
                  </label>
                  <input
                    type='password'
                    id='password'
                    {...register('password', { required: 'Password is required' })}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary block w-full p-2.5 ${
                      errors.password && 'border-red-500'
                    }`}
                    placeholder='Enter new password'
                  />
                  {errors.password && (
                    <p className='text-red-500 text-sm'>{errors.password.message}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='confirmPassword'
                    className='block mb-2 text-sm font-medium text-gray-900'
                  >
                    Confirm Password
                  </label>
                  <input
                    type='password'
                    id='confirmPassword'
                    {...register('confirmPassword', { required: 'Confirm Password is required' })}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary block w-full p-2.5 ${
                      errors.confirmPassword && 'border-[#b50000]'
                    }`}
                    placeholder='Re-enter new password'
                  />
                  {errors.confirmPassword && (
                    <p className='text-[#b50000] mt-3 font-medium text-sm'>{errors.confirmPassword.message}</p>
                  )}
                  {apiErrorMessage && (
                    <p className='text-[#b50000] mt-3 font-medium text-sm'>{apiErrorMessage}</p>
                  )}
                </div>
                <button
                  type='submit'
                  className='w-full text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center'
                >
                  Update password
                </button>
              </form>
            </>
          )}
        </div>
      </div>

      {modalMessage && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center' style={{zIndex:9999}}>
          <div className='bg-white px-6 py-8 rounded-lg shadow-lg w-[90%] md:w-[40%] text-center'>
            <p className='text-base md:text-[1.5rem] leading-[2rem] md:w-[80%] m-auto font-medium'>{modalMessage}</p>
            <button
              onClick={handleModalClose}
              className='mt-8 px-10 py-[0.65rem] bg-primary text-white rounded-[0.25rem]'
            >
              OK
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ForgotPasswordForm;
