import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ReferenceDot,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'February',
    uv: 0,
    failedTransactions: 6000,
    pendingTransactions: 2000,
    successfulTransactions: 4000,
    amt: 2400,
  },
  {
    name: 'March',
    uv: 1000,
    failedTransactions: 5000,
    pendingTransactions: 2500,
    successfulTransactions: 1398,
    amt: 2210,
  },
  {
    name: 'April',
    uv: 2000,
    failedTransactions: 4000,
    pendingTransactions: 3000,
    successfulTransactions: 800,
    amt: 2290,
  },
  {
    name: 'May',
    uv: 3000,
    failedTransactions: 3000,
    pendingTransactions: 3500,
    successfulTransactions: 3908,
    amt: 2000,
  },
  {
    name: 'June',
    uv: 4000,
    failedTransactions: 2000,
    pendingTransactions: 2000,
    successfulTransactions: 4800,
    amt: 2181,
  },
  {
    name: 'July',
    uv: 5000,
    failedTransactions: 1000,
    pendingTransactions: 2000,
    successfulTransactions: 3800,
    amt: 1000,
  },
  {
    name: 'August',
    uv: 6000,
    failedTransactions: 500,
    pendingTransactions: 2000,
    successfulTransactions: 3800,
    amt: 1000,
  },
];

interface Props {
  title?: string;
}

const LineChartComp = ({ title }: Props) => {
  const [filter, setFilter] = useState('6'); // Default filter: 3 months
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };
  const filteredData = () => {
    if (filter === '3') {
      return data.slice(-3); // Last 3 months
    } else if (filter === '6') {
      return data.slice(-6); // Last 6 months
    } else if (filter === '9') {
      return data; // Show all for 9 months (assuming all available data is within 9 months)
    } else if (filter === 'custom' && fromDate && toDate) {
      // Custom filter logic based on date range
      const from = new Date(fromDate);
      const to = new Date(toDate);
      return data.filter(({ name }) => {
        const month = new Date(`${name} 1, 2024`); // Assuming year is 2024
        return month >= from && month <= to;
      });
    }
    return data;
  };

  return (
    <div className='flex flex-col'>
      <h2 className='my-4 font-[600] text-xl'>{title}</h2>
      <div className='flex gap-4 mb-8 mt-4'>
        <select
          value={filter}
          onChange={handleFilterChange}
          className='p-2 border border-gray-300 rounded'
        >
          <option value='3'>Last 3 Months</option>
          <option value='6'>Last 6 Months</option>
          <option value='9'>Last 9 Months</option>
          <option value='custom'>Custom</option>
        </select>

        {filter === 'custom' && (
          <div className='flex gap-2'>
            <input
              type='date'
              value={fromDate}
              onChange={e => setFromDate(e.target.value)}
              className='p-2 border border-gray-300 rounded'
            />
            <input
              type='date'
              value={toDate}
              onChange={e => setToDate(e.target.value)}
              className='p-2 border border-gray-300 rounded'
            />
          </div>
        )}
      </div>
      <ResponsiveContainer width='100%' height={300}>
        <LineChart data={filteredData()}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' padding={{ left: 30, right: 30 }} />
          <YAxis
            type='number'
            domain={[0, 6000]}
            ticks={[0, 1000, 2000, 3000, 4000, 5000, 6000]}
            // allowDataOverflow
          />
          <Tooltip />
          <Legend />
          <Line
            type='monotone'
            dataKey='successfulTransactions'
            stroke='#689176'
            activeDot={{ r: 8 }}
            name='Successful Transactions'
          />
          <Line
            type='monotone'
            dataKey='pendingTransactions'
            stroke='#d5c100'
            activeDot={{ r: 8 }}
            name='Pending Transactions'
          />
          <Line
            type='monotone'
            dataKey='failedTransactions'
            stroke='#b97c7c'
            activeDot={{ r: 8 }}
            name='Failed Transactions'
          />
          <ReferenceLine y={0} label='50' ifOverflow='extendDomain' />
          <ReferenceLine
            segment={[
              {
                x: 'February',
                y: 100,
              },
              {
                x: 'March',
                y: 15000,
              },
            ]}
            ifOverflow='extendDomain'
          />
          <ReferenceDot x='Page A' y={17000} ifOverflow='discard' />
          <ReferenceArea
            x1='Page D'
            x2='Page E'
            y1={105000}
            y2={17000}
            ifOverflow='visible'
          />
        </LineChart>
      </ResponsiveContainer>
      <h1 className='my-4 font-[600] text-xl text-center mt-6'>-- Line Chart Description --</h1>
    </div>
  );
};

export default LineChartComp;
