import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Icons } from '../../assets/SVG-Icons';
import Button from '../../components/button/Button';
import { EmailVerification as verifyEmail } from '../../features/authSlice'; // Renamed the imported function

// Types for the API response
interface Partner {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  emailVerification: number;
  createdAt?: string;
  updatedAt?: string;
}

// const userData:Partner = {
//     id: 104,
//     firstName: "Harshitha",
//     lastName: "Dinesh",
//     email: "harshithadinesh97@gmail.com",
//     emailVerification: 1,
// }

// interface EmailVerificationResponse {
//     data: Partner | null;
// }
const EmailVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [error, setError] = useState<string | null>(null);

  const encryptedText = searchParams.get('h');

  useEffect(() => {
    // setPartner(userData);
    const fetchEmailVerification = async () => {
      if (!encryptedText) {
        setError('Invalid or missing verification link.');
        return;
      }

      try {
        // Make an API call to verify the email
        const response: any | undefined = await verifyEmail(encryptedText);

        if (response && response.data) {
          setPartner(response.data);
        } else {
          setError(
            'The verification link is expired. Please contact our support team'
          );
        }
      } catch (err) {
        setError(
          'There was an error verifying your email. Please try again later.'
        );
      }
    };

    fetchEmailVerification();
  }, [encryptedText]);

  return (
    <div className='flex flex-col items-center justify-center p-4 mt-[4rem] md:mt-[10rem]'>
      <Link className=' w-[100%] md:w-auto mb-6 ml-[6rem] md:ml-[4rem]' to='/'>
        {Icons.logo({ width: '300' })}
      </Link>

      {error ? (
        <div className='text-red-600 text-center text-[1.25rem] md:text-[1.5rem] md:mt-[2rem] leading-[2rem]'>
          <p>{error}</p>
        </div>
      ) : partner ? (
        <div className='text-center'>
          <h1 className='text-[1.25rem] md:text-[2rem] font-semibold mb-8 md:mb-[4rem] mt-12 leading-8 md:leading-12'>
            Congratulations {partner.firstName} {partner.lastName}! Your email
            is verified.
          </h1>
          <p className='mb-8 md:mb-16 text-gray-600 text-base md:text-[1.5rem]'>
            You can now access your dashboard by logging in.
          </p>
          <Link to='/signin' className='flex justify-center'>
            <Button primary>Log In </Button>
          </Link>
        </div>
      ) : (
        <div className='text-gray-600 text-center mt-[2rem] text-[1.25rem] md:text-[1.5rem] '>
          <p>Verifying your email, please wait...</p>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
