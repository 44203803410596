import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import React, { useState } from 'react';

const data = [
  { name: 'February', activeUsers: 2400, inactiveUsers: 1600 },
  { name: 'March', activeUsers: 1398, inactiveUsers: 1602 },
  { name: 'April', activeUsers: 400, inactiveUsers: 1600 },
  { name: 'May', activeUsers: 3908, inactiveUsers: 1092 },
  { name: 'June', activeUsers: 4800, inactiveUsers: 1200 },
  { name: 'July', activeUsers: 3800, inactiveUsers: 200 },
];

interface Props {
  title?: string;
}

const BarchartComp = ({ title }: Props) => {
  const [filter, setFilter] = useState('6'); // Default filter: 3 months
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const filteredData = () => {
    if (filter === '3') {
      return data.slice(-3); // Last 3 months
    } else if (filter === '6') {
      return data.slice(-6); // Last 6 months
    } else if (filter === '9') {
      return data; // Show all for 9 months (assuming all available data is within 9 months)
    } else if (filter === 'custom' && fromDate && toDate) {
      // Custom filter logic based on date range
      const from = new Date(fromDate);
      const to = new Date(toDate);
      return data.filter(({ name }) => {
        const month = new Date(`${name} 1, 2024`); // Assuming year is 2024
        return month >= from && month <= to;
      });
    }
    return data;
  };

  return (
    <div className='flex flex-col'>
      <h2 className='my-4 font-[600] text-xl'>{title}</h2>
      <div className='flex gap-4 mb-8 mt-4'>
        <select
          value={filter}
          onChange={handleFilterChange}
          className='p-2 border border-gray-300 rounded'
        >
          <option value='3'>Last 3 Months</option>
          <option value='6'>Last 6 Months</option>
          <option value='9'>Last 9 Months</option>
          <option value='custom'>Custom</option>
        </select>

        {filter === 'custom' && (
          <div className='flex gap-2'>
            <input
              type='date'
              value={fromDate}
              onChange={e => setFromDate(e.target.value)}
              className='p-2 border border-gray-300 rounded'
            />
            <input
              type='date'
              value={toDate}
              onChange={e => setToDate(e.target.value)}
              className='p-2 border border-gray-300 rounded'
            />
          </div>
        )}
      </div>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart
          data={filteredData()}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            type='number'
            domain={[0, 6000]}
            ticks={[0, 1000, 2000, 3000, 4000, 5000, 6000]}
          />
          <Tooltip
            contentStyle={{ backgroundColor: '#fff', border: '1px solid #ddd' }}
            itemStyle={{ background: 'white' }}
          />
          <Legend />
          <Bar dataKey='activeUsers' fill='#689176' name='Active Users' />
          <Bar dataKey='inactiveUsers' fill='#b97c7c' name='Inactive Users' />
        
        </BarChart>
      
      </ResponsiveContainer>
      <h1 className='my-4 font-[600] text-xl text-center mt-6'>-- Bar Chart Description --</h1>
    </div>
  );
};

export default BarchartComp;
