import Table from '../../components/table/Table';
import { config2, data2 } from '../../assets/dummy';
import { RootState } from '../../app/store';
import { useAppSelector } from '../../app/hooks';

const RecentTransactions = () => {
  const searchTerm = useAppSelector(
    (state: RootState) => state.search.searchTerm
  );

  const filteredData = data2.filter(item =>
    Object.values(item).some(val =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className='mt-8'>
      <h2 className='font-bold text-2xl mb-4'>Transations</h2>

      <Table
        data={filteredData}
        config={config2}
        showExportButton={false}
        selectCheckbox={false}
        allDataUrl='recent-transactions'
      />
    </div>
  );
};

export default RecentTransactions;
