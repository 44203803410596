import { useCallback } from 'react';

interface Header {
  label: string;
  key: string;
}

interface Config {
  headers: Header[];
}

interface DataRow {
  [key: string]: any;
}

const useExportCSV = (config: Config, data: DataRow[]) => {
  const handleExportCSV = useCallback(() => {
    const csvData = [
      config.headers.map(header => header.label),
      ...data.map(row => config.headers.map(header => row[header.key])),
    ];

    const csvContent =
      'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [config, data]);

  return handleExportCSV;
};

export default useExportCSV;
