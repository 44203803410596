import React from 'react';
import { useNavigate } from 'react-router-dom';
import BarchartComp from '../../components/barChart/BarChart';
import Card from '../../components/card/Card';
import LineChartComp from '../../components/lineChart/LineChart';

interface CardPath {
  [key: string]: string; // Map card title (string) to route (string)
}

const cardPaths: CardPath = {
  'Total Customers': '/reporting/total-customers',
  'Total # Transactions': '/reporting/total-transactions',
  'Total Value Transactions': '/reporting/total-value-transactions',
  'Partner Earnings': '/reporting/partner-earnings',
  'Customer total Earning': '/reporting/customer-earnings',
  '# Enrolled Cards': '/reporting/enrolled-cards',
};

const Dashboard = () => {
  const navigate = useNavigate();

  const handleCardClick = (title: string) => {
    const path = cardPaths[title];
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className='flex flex-col gap-6 max-w-[85rem] mx-auto md:mt-12'>
      <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-5'>
        <Card title='Total Customers' value='8465' onClick={() => handleCardClick('Total Customers')} />
        <Card title='Total # Transactions' value='954' onClick={() => handleCardClick('Total # Transactions')} />
        <Card title='Total Value Transactions' value='954' onClick={() => handleCardClick('Total Value Transactions')} />
        <Card title='Partner Earnings' value='$45,231.45' onClick={() => handleCardClick('Partner Earnings')} />
        <Card title='Customer total Earning' value='$45,231.45' onClick={() => handleCardClick('Customer total Earning')} />
        <Card title='# Enrolled Cards' value='513' onClick={() => handleCardClick('# Enrolled Cards')} />
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
        <BarchartComp title='Customers Count' />
        <LineChartComp title='Transactions Count' />
      </div>

      {/* Admin Info Section */}
      <div className='mt-8  border border-gray-300 rounded-lg mb-8'>
        <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
          Updates and Reminders
        </h2>
        <div className=' px-6 py-6 text-[1.25rem]'>
        <div className='mb-4'>
          <strong>Next Payout Date:</strong> <span>October 25, 2024</span>
        </div>
        <div className='mb-4'>
          <strong>Next Billing Date:</strong> <span>November 1, 2024</span>
        </div>
        <h3 className='text-md font-semibold mt-6'>Warnings/Errors/Issues</h3>
        <ul className='list-disc list-inside'>
          <li className='border-b border-gray-300 py-4'>Card issues: Please update your card information.</li>
          <li className='border-b border-gray-300 py-4'>Payment issues: Payment for last month has failed.</li>
          <li className='border-b border-gray-300 py-4'>Missed payment: You missed the payment due on October 15, 2024.</li>
          <li className='border-b border-gray-300 py-4'>Need to upgrade: Your current plan does not support more than 500 enrolled cards.</li>
          <li className='border-b border-gray-300 py-4'>You exceeded the card enrollment limit: Upgrade your plan to enroll more cards.</li>
        </ul>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
