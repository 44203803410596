import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store'; // Adjust the path to your store
import Button from '../../components/button/Button';
import toast,  { Toaster } from 'react-hot-toast';
import { useAppDispatch } from '../../app/hooks';
import { UpdatePartnerInfo } from '../../features/partnerSlice';
import Spinner from '../../components/spinner/Spinner';

interface CompanyData {
  companyName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  contactEmail: string;
  companyPhone: string;
  salesAgentId: string;
  id: string;
}

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phonePattern = /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/;
const salesIdPattern = /^[0-9]{5}$/;
interface ModalProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className={`bg-[#fff0] p-8 rounded-lg  relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4 flex justify-center`}
      >
        <p className='text-base text-gray-700'>{children}</p>
      </div>
    </div>
  );
};

const CompanyInfo = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<CompanyData>();
  const userDetails = useSelector((state: RootState) => state.auth.userDetails);
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const isLoading = useSelector((state: RootState) => state.partner.loading);
  const userId:any = useSelector((state: RootState) => state.auth.user?.id);
  const partnerId:any = useSelector((state: RootState) => state.auth.user?.partnerId);
  useEffect(() => {
    if (userDetails?.partner) {
      console.log(userDetails);
      setValue('companyName', userDetails.partner.name);
      setValue('addressOne', userDetails.partner.address_1);
      setValue('addressTwo', userDetails.partner.address_2);
      setValue('city', userDetails.partner.city);
      setValue('state', userDetails.partner.state);
      setValue('zipCode', userDetails.partner.zipCode);
      setValue('country', userDetails.partner.country);
      setValue('contactEmail', userDetails.partner.email);
      setValue('companyPhone', userDetails.partner.phone);
      setValue('salesAgentId', userDetails.partner.salesAgentId);

    }
  }, [userDetails, setValue]);

  const onSubmit: SubmitHandler<CompanyData> = async (data) => {
    try {
      const companyFormData = new FormData();
  
      
      companyFormData.append('id', partnerId as string);
  
      
      Object.entries(data).forEach(([key, value]) => {
        if (value && value !== null) {
          companyFormData.append(key, value as string); 
        } else {
          companyFormData.append(key, ''); 
        }
      });
  
      
      for (let [key, value] of companyFormData.entries()as any) {
        console.log(`${key}: ${value}`);
      }
  
      
      toast.success('Company information updated successfully');
      
      
      dispatch(UpdatePartnerInfo(userId, companyFormData));
  
    } catch (error) {
      console.error('Error updating company information:', error);
      toast.error('Failed to update company information. Please try again.');
    }
  };
  

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      <div className='flex justify-between items-center mb-6 mt-2'>
        <h2 className='text-xl font-semibold'>Company Information</h2>
        <button
          onClick={handleEditToggle}
          className='text-[#d50000] text-[1.25rem] pr-1 font-semibold'
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>
      
      <div className=' border border-gray-200 rounded-b-lg'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=''>


         
          <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
            Company Information
          </h2>

          <div className='m-8' >

          <div className='mb-6 mt-4'>
            <label className='block text-[#000000] text-sm font-[500] mb-2'>
              Company Name *
            </label>
            <input
              type='text'
              className='register-input w-full'
              {...register('companyName', { required: 'Company name is required' })}
              disabled={!isEditing}
            />
            {errors.companyName && (
              <span className='text-red-600 text-[0.75rem] font-semibold'>
                {errors.companyName.message}
              </span>
            )}
          </div>

          <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Address Line 1 *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('addressOne')}
                disabled={!isEditing}
              />
              {errors.addressOne && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.addressOne.message}
                </span>
              )}
            </div>
            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Address Line 2
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('addressTwo')}
                disabled={!isEditing}
              />
            </div>
          </div>

          <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                City *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('city')}
                disabled={!isEditing}
              />
              {errors.city && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.city.message}
                </span>
              )}
            </div>

            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                State *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('state')}
                disabled={!isEditing}
              />
              {errors.state && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.state.message}
                </span>
              )}
            </div>
          </div>

          <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Zip Code *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('zipCode')}
                disabled={!isEditing}
              />
              {errors.zipCode && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.zipCode.message}
                </span>
              )}
            </div>

            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Country *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('country')}
                disabled={!isEditing}
              />
              {errors.country && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.country.message}
                </span>
              )}
            </div>
          </div>

          <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Contact Email *
              </label>
              <input
                type='email'
                className='register-input w-full'
                {...register('contactEmail', {
                  required: 'Contact email is required',
                  pattern: { value: emailPattern, message: 'Invalid email address' }
                })}
                disabled={!isEditing}
              />
              {errors.contactEmail && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.contactEmail.message}
                </span>
              )}
            </div>

            <div>
              <label className='block text-[#000000] text-sm font-[500] mb-2'>
                Company Phone *
              </label>
              <input
                type='text'
                className='register-input w-full'
                {...register('companyPhone', {
                  required: 'Company phone is required',
                  pattern: { value: phonePattern, message: 'Invalid phone number' }
                })}
                disabled={!isEditing}
              />
              {errors.companyPhone && (
                <span className='text-red-600 text-[0.75rem] font-semibold'>
                  {errors.companyPhone.message}
                </span>
              )}
            </div>
          </div>

          <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
          <div>
          <label className='block text-[#000000] text-sm font-[500] mb-2'>
            Sales Agent ID *
          </label>
          <input
            type='number'
            className='register-input w-full'
            {...register('salesAgentId', {
              required: 'Sales Agent ID is required',
              pattern: { value: salesIdPattern, message: 'Invalid Sales Agent ID' }
            })}
            disabled={!isEditing}
          />
          {errors.salesAgentId && (
            <span className='text-red-600 text-[0.75rem] font-semibold'>
              {errors.salesAgentId.message}
            </span>
          )}
        </div>
        </div>


          {isEditing && (
            <div className='mt-8 w-full flex justify-end gap-4'>
              <Button
             type='submit'
             className={`w-full  text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
           >
            Save Changes
           </Button>
            </div>
          )}
          </div>
 </div>
        </form>
        <Toaster position='top-center' />
        <Modal isLoading={isLoading}>{isLoading && <Spinner />}</Modal>
      </div>
    </>
  );
};

export default CompanyInfo;
