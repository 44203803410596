import React, { PropsWithChildren } from 'react';

type PickOnly<T, K extends keyof T> = Pick<T, K> & {
  [P in Exclude<keyof T, K>]?: never;
};

type AtMostOneKeys<T> =
  | PickOnly<T, never>
  | { [K in keyof T]: PickOnly<T, K> }[keyof T];

interface Props {
  onClick: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  primary: boolean;
  secondary: boolean;
  isLoading?: boolean;
}

type TypeProps = Partial<Props> &
  AtMostOneKeys<Pick<Props, 'primary' | 'secondary'>>;

const Button: React.FC<PropsWithChildren<Partial<TypeProps>>> = ({
  onClick,
  disabled = false,
  type = 'button',
  className = '',
  children,
  primary,
  secondary,
  isLoading = false,
}) => {
  let btnClasses;
  if (primary)
    btnClasses = ` text-white ${
      isLoading
        ? 'bg-[#a3a3a3] border-[#cecece] hover:bg-[#a3a3a3]'
        : 'bg-primary hover:bg-[#0125b3] border-primary'
    }`;
  else if (secondary)
    btnClasses =
      'bg-secondary-200 text-secondary-400 border-secondary-200 hover:text-secondary-400 hover:bg-white';

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`flex justify-center items-center gap-2 px-4 py-3 rounded-lg border whitespace-nowrap min-w-[10rem] transition-all ${className} ${btnClasses} ${
        isLoading && 'bg-[#a3a3a3] border-[#a3a3a3] cursor-default'
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
