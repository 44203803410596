import { Outlet } from 'react-router';
import NavBarLandingPage from '../components/navBarLandingPage/NavBarLandingPage';
// import Footer from '../components/footer/Footer';

const Layout = () => {
  return (
    <>
      <NavBarLandingPage />
      <main className='flex flex-col font-Montserrat leading-[19.49px] w-full'>
        <div className='bg-white'>
          <Outlet />
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
