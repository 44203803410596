import React, { useState } from 'react';
import BrandingInformation from '../../components/branding/BrandingInformation';



const PayoutAccount = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  return (
    <div>
      <div className='flex justify-between items-center mb-6'>
        <h2 className='text-xl font-semibold mt-2'>Payout account</h2>
        <button
          onClick={handleEditToggle}
          className='text-[#d50000] text-[1.25rem] pr-1 font-semibold'
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>
      <div
        className={`mb-[10rem] ${!isEditing ? ' bg-opacity-90 pointer-events-none' : ''}`}
        style={{
          backgroundColor: !isEditing ? 'rgba(255, 255, 255, 0.5)' : 'transparent', // or any other background color
        }}
      >
        <BrandingInformation payoutAccountPage={true} />
      </div>
    
    </div>
  );
};
export default PayoutAccount;
