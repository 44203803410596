import React, { useState, useEffect } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import faqData from '../../assets/json/faqData.json';
import { FiPlusCircle } from 'react-icons/fi';
import { AiOutlineMinusCircle } from 'react-icons/ai';
// import FaqPage from '../faq/FaqPage';

interface QuestionsDropdownProps {
  faqPage?: boolean;
}


const QuestionsDropdown = ({ faqPage = false }: QuestionsDropdownProps) => {
  const { pathname } = useLocation();
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (pathname && pathname.includes('business-faq')) {
      setExpand(true);
    }
  }, [pathname]);
  useEffect(() => {
    if (faqPage === true) {
      setExpand(true);
    }
  }, [faqPage]);
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null
  );

  return (
    <>
      <div className='container-fluid md:container md:mx-auto md:w-[85%]'>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-10 my-4 md:my-8 font-bold text-[#1133BB] text-center'>
          Questions?
        </h2>
        <p className='md:w-[70%] md:mx-auto text-center text-xl mb-[2rem] md:mb-[4rem]'>
          If you have questions, our FAQ section is here to help. Browse through
          to find detailed answers and helpful information on a variety of
          topics. We hope you'll find everything you need to enhance your
          experience.
        </p>

        <div className='mt-6 mx-4 md:mx-auto '>
          {(expand
            ? faqData
            : faqData.filter(question => [20, 27, 28].includes(question.id))
          ).map((question, index) => (
            <Quest
              key={question.id}
              el={question}
              isOpen={openQuestionIndex === index}
              toggle={() =>
                setOpenQuestionIndex(openQuestionIndex === index ? null : index)
              }
            />
          ))}
        </div>

        {!expand && (
          <Link to={'/business-faq'}>
            <div className=' flex flex-row justify-end my-8 gap-2 items-center'>
              <p className='text-primary font-semibold hover:underline'>
                Read more{' '}
              </p>
              <FaExternalLinkAlt className='text-primary font-semibold' />
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

interface QuestProps {
  el: {
    id: number;
    question: string;
    answer: string;
  };
  isOpen: boolean;
  toggle: () => void;
}

// Question component
export const Quest: React.FC<QuestProps> = ({ el, isOpen, toggle }) => {
  return (
    <>
      <hr className='w-full' />
      <div
        className='flex flex-row justify-between items-center py-4 cursor-pointer'
        onClick={toggle}
        key={el.id}
      >
        <p className='text-[20px] md:text-xl py-4 md:py-5 pr-4 md:pr-0 text-[#5A5548] font-medium md:font-[400]'>
          {el.question}
        </p>
        <p
          className='text-[1.65rem] md:text-[2rem] text-[#000000]'
          // style={
          //   isOpen
          //     ? { padding: '0.125rem 0.25rem 0.25rem' }
          //     : { padding: '0.125rem 0.25rem' }
          // }
        >
          {isOpen ? <AiOutlineMinusCircle /> : <FiPlusCircle />}
        </p>
      </div>
      {isOpen && <p className='text-[#5A5548] mb-4 text-base'>{el.answer}</p>}
      <hr className='w-full' />
    </>
  );
};

export default QuestionsDropdown;
