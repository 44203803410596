import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Layouts
import Layout from './layout/Layout';
import LayoutDashboard from './layout/LayoutDashboard';

// Pages
import Dashboard from './pages/dashboard/Dashboard';
import Reporting from './pages/reporting/Reporting';
import Signin from './pages/signin/Signin';
import SignUp from './pages/signup/Main';
import UserManagement from './pages/userManagement/UserManagement';
import MarketingMaterial from './pages/marketingMaterial/MarketingMaterial';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import RecentTransactions from './pages/recentTransactions/RecentTransactions';
import SubscriptionPayments from './pages/subscriptionPayments/SubscriptionPayments';
import Support from './pages/support/Support';
import Profile from './pages/profile/Profile';
import Faq from './pages/faq/Faq';
import QR from './pages/marketingMaterial/QR';
import Email from './pages/marketingMaterial/Email';
import SocialMedia from './pages/marketingMaterial/SocialMedia';
import CompanyInfo from './pages/companyInfo/CompanyInfo';
import ProgramBranding from './pages/programBranding/ProgramBranding';
import PayoutAccount from './pages/payoutAccount/PayoutAccount';
import B2BLanding from './pages/b2b/B2BLanding';
import Invoices from './pages/invoices/Invoices';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import AboutUs from './pages/aboutUs/AboutUs';
import FaqPage from './pages/faq/FaqPage';
import InactiveDashboard from './pages/dashboard/InactiveDashboard';
import InReviewDashboard from './pages/dashboard/InReviewDashboard';
import EmailVerification from './pages/thankyou/emailVerification';
import {
  ProtectedRoute,
  PublicRoute,
} from './components/protectedRoute/ProtectedRoute';
import Spinner from './components/spinner/Spinner';
import { AppDispatch } from './app/store';

// Redux
import { RootState } from './app/store';
import { fetchUserDetails, setUser } from './features/authSlice';
import NotificationsInbox from './pages/notifications-inbox/NotificationsInbox';
import NotificationDetails from './pages/notification-details/NotificationDetails';
import Settings from './pages/settings/Settings';
import News from './components/news/News';
import CustomerTotalEarnings from './pages/reporting/CustomerTotalEarnings';
import EnrolledCards from './pages/reporting/EnrolledCards';
import PartnerEarnings from './pages/reporting/PartnerEarnings';
import TotalCustomers from './pages/reporting/TotalCustomers';
import TotalTransactions from './pages/reporting/TotalTransactions';
import TotalValueTransactions from './pages/reporting/TotalValueTransactions';
import ProductImages from './pages/marketingMaterial/ProductImages';
import Banners from './pages/marketingMaterial/Banners';
import Messaging from './pages/marketingMaterial/Messaging';
import PrintMeterial from './pages/marketingMaterial/PrintMeterial';

export const getCookieValue = (name: string): string | null => {
  const value = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`));

  return value ? value.split('=')[1] : null;
};
export default function App() {
  const dispatch = useDispatch<AppDispatch>();

  const token = getCookieValue('token');
  const localUserData: any = localStorage.getItem('userDetails');
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const userStatus = useSelector((state: RootState) => state.auth.status);
  const userDetails = JSON.parse(localUserData);
  const [accountStatus, setAccountStatus] = useState<null | string>(null);
  useEffect(() => {
    const fetchData = async (id: string) => {
      dispatch(fetchUserDetails(id));
      return true;
    };

    if (token) {
      if (userDetails) {
        const { email, id, partnerId } = userDetails;
        dispatch(setUser({ email, id, partnerId }));
        const fetchUserData: any = fetchData(userDetails.id);
        if (fetchUserData) {
          setAccountStatus(userStatus);
        }
      }
    }
  }, [dispatch, token, userId, userDetails, userStatus]);

  //Comment from here if Brand Info should remain
  // const BrandInfoState = useSelector(
  //   (state: RootState) => state.partner.brandInfo
  // );
  // const BankInfoState = useSelector(
  //   (state: RootState) => state.partner.paymentInfo
  // );

  // useEffect(() => {
  //   if (BrandInfoState !== null && BankInfoState !== null) {
  //     setPrimaryColor(BrandInfoState.primaryColor);
  //     setSecondaryColor(BrandInfoState.secondaryColor);
  //     if(BrandInfoState.brandInfo === 'completed') {
  //       setAccountStatus('inreview');
  //       dispatch(setUserStatus('inreview'));
  //     }
  //   } else setAccountStatus(userStatus);
  // }, [BankInfoState, BrandInfoState, dispatch, userStatus]);

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path='/thankyou' element={<EmailVerification />} />
          <Route element={<Layout />}>
            {/* Public Routes */}
            <Route path='/' element={<B2BLanding />} />
            <Route
              path='/signin'
              element={
                <PublicRoute>
                  <Signin />
                </PublicRoute>
              }
            />
            <Route
              path='/signup'
              element={
                <PublicRoute>
                  <SignUp />
                </PublicRoute>
              }
            />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/business-faq' element={<FaqPage />} />
            <Route
              path='/t&c-privacy'
              element={<PrivacyPolicyPage scrollToId='privacy' />}
            />
            `
          </Route>

          <Route element={<LayoutDashboard />}>
            {/* Protected Routes */}
            <Route
              path='/dashboard'
              element={
                <ProtectedRoute>
                  {accountStatus === 'null' ? (
                    <Spinner />
                  ) : accountStatus === 'active' ? (
                    <Dashboard />
                  ) : accountStatus === 'inreview' ? (
                    <InReviewDashboard />
                  ) : accountStatus === 'inactive' ? (
                    <InactiveDashboard />
                  ) : (
                    <Spinner />
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material'
              element={
                <ProtectedRoute>
                  <MarketingMaterial />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/qr'
              element={
                <ProtectedRoute>
                  <QR />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/email'
              element={
                <ProtectedRoute>
                  <Email />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/banners'
              element={
                <ProtectedRoute>
                  <Banners />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/social-media'
              element={
                <ProtectedRoute>
                  <SocialMedia />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/messaging'
              element={
                <ProtectedRoute>
                  <Messaging />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/print'
              element={
                <ProtectedRoute>
                  <PrintMeterial />
                </ProtectedRoute>
              }
            />
            <Route
              path='/marketing-material/product-images'
              element={
                <ProtectedRoute>
                  <ProductImages />
                </ProtectedRoute>
              }
            />
            <Route
              path='/user-management'
              element={
                <ProtectedRoute>
                  <UserManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting'
              element={
                <ProtectedRoute>
                  <Reporting />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/total-customers'
              element={
                <ProtectedRoute>
                  <TotalCustomers />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/total-transactions'
              element={
                <ProtectedRoute>
                  <TotalTransactions />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/total-value-transactions'
              element={
                <ProtectedRoute>
                  <TotalValueTransactions />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/partner-earnings'
              element={
                <ProtectedRoute>
                  <PartnerEarnings />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/customer-earnings'
              element={
                <ProtectedRoute>
                  <CustomerTotalEarnings />
                </ProtectedRoute>
              }
            />
            <Route
              path='/reporting/enrolled-cards'
              element={
                <ProtectedRoute>
                  <EnrolledCards />
                </ProtectedRoute>
              }
            />

            <Route
              path='/company-info'
              element={
                <ProtectedRoute>
                  <CompanyInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path='/program-branding'
              element={
                <ProtectedRoute>
                  <ProgramBranding />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscription'
              element={
                <ProtectedRoute>
                  <SubscriptionPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path='/payout-account'
              element={
                <ProtectedRoute>
                  <PayoutAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path='/invoices'
              element={
                <ProtectedRoute>
                  <Invoices />
                </ProtectedRoute>
              }
            />
            <Route
              path='/privacy-policy'
              element={
                <ProtectedRoute>
                  <PrivacyPolicy />
                </ProtectedRoute>
              }
            />
            <Route
              path='/recent-transactions'
              element={
                <ProtectedRoute>
                  <RecentTransactions />
                </ProtectedRoute>
              }
            />
            <Route
              path='/support'
              element={
                <ProtectedRoute>
                  <Support />
                </ProtectedRoute>
              }
            />
            <Route
              path='/profile'
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/faq'
              element={
                <ProtectedRoute>
                  <Faq />
                </ProtectedRoute>
              }
            />
            <Route
              path='/notification/:id'
              element={
                <ProtectedRoute>
                  <NotificationDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path='/notifications-inbox'
              element={
                <ProtectedRoute>
                  <NotificationsInbox />
                </ProtectedRoute>
              }
            />
            <Route
              path='/settings'
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path='/news'
              element={
                <ProtectedRoute>
                  <News />
                </ProtectedRoute>
              }
            />
            {/* End of Protected Routes */}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
