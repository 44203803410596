import React, { useState } from 'react';
import Table from '../../components/table/Table';
import { config2, data2 } from '../../assets/dummy';
import BarchartComp from '../../components/barChart/BarChart';
import LineChartComp from '../../components/lineChart/LineChart';
import { VscGraph } from 'react-icons/vsc';
import { FaRegRectangleList } from 'react-icons/fa6';

const TotalTransactions = () => {
  const [view, setView] = useState<'charts' | 'list'>('charts');

  return (
    <div className='mx-[8rem]  p-4 text-center'>
      {/* Customer Count and Description */}
     

      {/* Toggle Button */}
      <div className='flex flex-row justify-between align-middle center mt-[5rem]'>
        <h1 className=' font-[500] text-[2rem]'>Total Transactions</h1>
        <div>
          <button
            className={`px-5 py-2 text-[1rem] ${
              view === 'charts'
                ? 'bg-primary text-white border-white px-4 py-[9px]'
                : 'bg-white text-primary border-[#c4c4e5] border-[1px] px-4 py-2'
            }`}
            onClick={() => setView(view === 'charts' ? 'list' : 'charts')}
          >
            <VscGraph />
          </button>
          <button
            className={`${
              view === 'list'
                ? 'bg-primary text-white border-white px-4 py-[9px]'
                : 'bg-white text-primary border-[#c4c4e5] border-[1px] px-4 py-2 '
            } text-[1rem]`}
            onClick={() => setView(view === 'charts' ? 'list' : 'charts')}
          >
            <FaRegRectangleList />
          </button>
        </div>
      </div>
      <div className='mb-[5rem] flex items-center gap-2 mt-4'>
      
        <div className='text-xl font-semibold'>8465</div>
        <div className='text-green-600'>+20.1% from last month</div>
      </div>
      {/* Display Charts or List View */}
      {view === 'charts' && (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
          <BarchartComp title='Total Transaction Count' />
          <LineChartComp title='.' />
        </div>
      )}

      {view === 'list' && (
        <div className='p-4'>
          <Table
            data={data2}
            config={config2}
            showExportButton={true}
            selectCheckbox={false}
            allDataUrl='recent-transactions'
            width='w-full'
          />
        </div>
      )}
    </div>
  );
};

export default TotalTransactions;
