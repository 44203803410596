import QuestionsDropdown from '../b2b/QuestionsDropdown';
import Footer from '../b2b/Footer';
import React, { useEffect } from 'react';

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <QuestionsDropdown />
      <div className='mb-8'></div>
      <Footer />
    </>
  );
};

export default FaqPage;
