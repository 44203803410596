import { FaRegListAlt } from 'react-icons/fa';
import { LiaQrcodeSolid } from 'react-icons/lia';
import { RiFlag2Line } from 'react-icons/ri';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { TiImage, TiPrinter } from 'react-icons/ti';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { RiCalendarCheckFill } from 'react-icons/ri';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { VscGraph } from 'react-icons/vsc';
import { MdOutlineBrandingWatermark } from 'react-icons/md';
import { MdPayment } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { LuUsers2 } from 'react-icons/lu';
import { TbCreditCard } from 'react-icons/tb';
import { FaFileInvoiceDollar } from 'react-icons/fa6';
import { BiMessageDetail } from 'react-icons/bi';

export const sideBarItems = [
  {
    id: 1,
    name: 'Dashboard',
    path: '/dashboard',
    icon: VscGraph,
    children: [],
  },
  {
    id: 2,
    name: 'Marketing Material',
    path: '',
    icon: FaRegListAlt,
    children: [
      {
        id: 1,
        name: 'QR & Links',
        path: '/marketing-material/qr',
        icon: LiaQrcodeSolid,
        children: [],
      },
      {
        id: 2,
        name: 'Messaging',
        path: '/marketing-material/messaging',
        icon: BiMessageDetail,
        children: [],
      },
      {
        id: 3,
        name: 'Email',
        path: '/marketing-material/email',
        icon: MdOutlineMarkEmailRead,
        children: [],
      },
      {
        id: 4,
        name: 'Social Media',
        path: '/marketing-material/social-media',
        icon: MdOutlineSocialDistance,
        children: [],
      },
      {
        id: 5,
        name: 'Banners',
        path: '/marketing-material/banners',
        icon: RiFlag2Line,
        children: [],
      },
      {
        id: 6,
        name: 'Print Material',
        path: '/marketing-material/print',
        icon: TiPrinter,
        children: [],
      },
      {
        id: 7,
        name: 'Product Images',
        path: '/marketing-material/product-images',
        icon: TiImage,
        children: [],
      },
    ],
  },
  {
    id: 3,
    name: 'Reporting',
    path: '',
    icon: LuFileSpreadsheet,
    children: [
      {
        id: 3.1,
        name: 'Total Customers',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/total-customers',
        children: [],
      },
      {
        id: 3.2,
        name: 'Total # Transactions',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/total-transactions',
        children: [],
      },
      {
        id: 3.2,
        name: 'Total Value Transactions',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/total-value-transactions',
        children: [],
      },
      {
        id: 3.4,
        name: 'Partner Earnings',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/partner-earnings',
        children: [],
      },
      {
        id: 3.5,
        name: 'Customer Total Earnings',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/customer-earnings',
        children: [],
      },
      {
        id: 3.6,
        name: '# Enrolled Cards',
        icon: IoIosInformationCircleOutline,
        path: '/reporting/enrolled-cards',
        children: [],
      },
    ],
  },
  {
    id: 4,
    name: 'Program Management',
    icon: RiCalendarCheckFill,
    children: [
      {
        id: 1,
        name: 'Company Info',
        icon: IoIosInformationCircleOutline,
        path: '/company-info',
        children: [],
      },
      {
        id: 2,
        name: 'Branding',
        icon: MdOutlineBrandingWatermark,
        path: '/program-branding',
        children: [],
      },

      {
        id: 3,
        name: 'Users',
        path: '/user-management',
        icon: LuUsers2,
        children: [],
      },
      {
        id: 5,
        name: 'Billing',
        icon: TbCreditCard,
        children: [
          {
            id: 5.1,
            name: 'Program Plan',
            icon: MdPayment,
            path: 'subscription',
            children: [],
          },
          {
            id: 5.2,
            name: 'Payout Account',
            icon: RiSecurePaymentLine,
            path: '/payout-account',
            children: [],
          },
          {
            id: 5.3,
            name: 'Invoices',
            icon: FaFileInvoiceDollar,
            path: '/invoices',
            children: [],
          },
        ],
      },
    ],
  },
];

export const countries = [
  { id: 1, countryName: 'USA', value: 'usa' },
  { id: 2, countryName: 'Lebanon', value: 'lebanon' },
  { id: 3, countryName: 'uae', value: 'UAE' },
  { id: 4, countryName: 'Canada', value: 'canada' },
];

export const data = [
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: 'Yes',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '7/20/2073',
  },
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: '8/3/2073',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '8/16/2042',
  },
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: '12/5/2115',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '1/17/2119',
  },
];

export const config = {
  headers: [
    { key: 'no', label: 'S.No' },
    { key: 'amount', label: 'Amount' },
    { key: 'status', label: 'Status' },
    { key: 'date', label: 'Date' },
  ],
};

export const data2 = [
  {
    no: '106',
    customerId: '48',
    offerName: 'Laptop',
    date: '7/20/2073',
    purchaseAmount: '7',
    cashback: '11%',
  },
  {
    no: '237',
    customerId: '95',
    offerName: 'Laptop PC',
    date: '7/20/2073',
    purchaseAmount: '30',
    cashback: '79%',
  },
  {
    no: '248',
    customerId: '87',
    offerName: 'Laptop',
    date: '8/3/2073',
    purchaseAmount: '9',
    cashback: '85%',
  },
  {
    no: '218',
    customerId: '50',
    offerName: 'Laptop PC',
    date: '8/16/2042',
    purchaseAmount: '26',
    cashback: '59%',
  },
  {
    no: '124',
    customerId: '14',
    offerName: 'Laptop',
    date: '12/5/2115',
    purchaseAmount: '1',
    cashback: '34%',
  },
  {
    no: '218',
    customerId: '55',
    offerName: 'Laptop PC',
    date: '1/17/2119',
    purchaseAmount: '30',
    cashback: '24%',
  },
];

export const config2 = {
  headers: [
    { key: 'no', label: 'S.No' },
    { key: 'customerId"', label: 'CustomerId"' },
    { key: 'offerName', label: 'OfferName' },
    { key: 'purchaseAmount', label: 'PurchaseAmount' },
    { key: 'cashback', label: 'Cashback' },
    { key: 'date', label: 'Date' },
  ],
};

export const usersData = [
  {
    id: 18,
    firstName: 'Marvin',
    lastName: 'Young',
    email: 'ke@uriac.us',
    phone: '33211634',
    dateCreated: '10/30/2063',
    role: 'Admin',
    status: 'Active',
  },
  {
    id: 62,
    firstName: 'Augusta',
    lastName: 'Little',
    email: 'hiwreci@koc.is',
    phone: '67455561',
    dateCreated: '8/21/2102',
    role: 'Normal',
    status: 'Active',
  },
  {
    id: 55,
    firstName: 'EdnaMarvin',
    lastName: 'Reyes',
    email: 'tamekno@eso.kh',
    phone: '38076488',
    dateCreated: '3/13/2113',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 92,
    firstName: 'AlejandroMarvin',
    lastName: 'Kelly',
    email: 'rivu@ki.my',
    phone: '59102191',
    dateCreated: '5/1/2107',
    role: 'Normal',
    status: 'Active',
  },
  {
    id: 78,
    firstName: 'LandonMarvin',
    lastName: 'Lindsey',
    email: 'mulid@kufe.gg',
    phone: '21493063',
    dateCreated: '12/24/2050',
    role: 'Normal',
    status: 'Inactive',
  },
  {
    id: 81,
    firstName: 'AmandaMarvin',
    lastName: 'Bush',
    email: 'fido@be.si',
    phone: '88181914',
    dateCreated: '9/1/2024',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 52,
    firstName: 'Saddam',
    lastName: 'Helles',
    email: 'shilles@madrewards.com',
    phone: '0108496549',
    dateCreated: '07/28/2024',
    role: 'Admin',
    password: '123123',
    confirmPassword: '123123',
    status: 'Inactive',
  },
];

export const usersConfig = {
  headers: [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'dateCreated', label: 'Date Created' },
    { key: 'role', label: 'Role' },
    { key: 'status', label: 'Status' },
  ],
};
