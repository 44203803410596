import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import axios from 'axios';
import envConfig from '../utils/config';
import { setUserStatus, fetchUserDetails } from './authSlice';

interface PartnerState {
  brandInfo: any | null;
  paymentInfo: any | null;
  brandInfoDraft: any | null;
  paymentInfoDraft: any | null;
  primaryColor: string;
  secondaryColor: string;
  loading: boolean;
}

const initialState: PartnerState = {
  brandInfo: null,
  paymentInfo: null,
  brandInfoDraft: null,
  paymentInfoDraft: null,
  primaryColor: '#1133BB',
  secondaryColor: '#66CC99',
  loading: false,
};

const PartnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setBrandInfo: (state, action: PayloadAction<any>) => {
      state.brandInfo = action.payload;
    },
    setPaymentInfo: (state, action: PayloadAction<any>) => {
      state.paymentInfo = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setBrandInfoDraft: (state, action: PayloadAction<any>) => {
      state.brandInfoDraft = action.payload;
    },
    setPaymentInfoDraft: (state, action: PayloadAction<any>) => {
      state.paymentInfoDraft = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setBrandInfo,
  setPaymentInfo,
  setPrimaryColor,
  setSecondaryColor,
  setBrandInfoDraft,
  setPaymentInfoDraft,
  setLoading,
} = PartnerSlice.actions;

export const PartnerReducer = PartnerSlice.reducer;

export const UpdateBrandInfo =
  (partnerId: string, brandInfo: FormData): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true)); // Start loading

    try {
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/b`,
        brandInfo,
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
          },
        }
      );

      if (response.status === 200) {
        const brandInfoField = brandInfo.get('brandInfo');
        if (brandInfoField === 'completed') {
          dispatch(setUserStatus('inreview'));
        }
        dispatch(GetBrandInfo(partnerId));
      }

      return response;
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false)); // Stop loading
    }
  };

export const GetBrandInfo =
  (partnerId: string): AppThunk =>
  async (dispatch, getState) => {
    if (partnerId) {
      try {
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/g/b`,
          { partnerId: 2 },
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );
        if (response.status === 200) {
          dispatch(setBrandInfo(response.data));
        }
      } catch (error) {
        console.log('error', error);
        return error;
      }
    } else {
      console.log('no partner id');
    }
  };

export const UpdateBankInfo =
  (partnerId: string, payoutInfo: FormData): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true)); // Start loading

    try {
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/a/b/d`,
        payoutInfo,
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        dispatch(GetBankInfo(partnerId));
      }

      return response;
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false)); // Stop loading
    }
  };

export const GetBankInfo =
  (partnerId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/g/b/d`,
        { partnerId },
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setPaymentInfo(response.data));
      }
    } catch (error) {
      console.log('error', error);
      return error;
    }
  };

export const ContactUs =
  (formData: any): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/sys/sys/s`,
        formData,
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const UpdatePartnerInfo =
  (userId: string, partnerInfo: FormData): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/u`,
        partnerInfo,
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        dispatch(fetchUserDetails(userId));
      }

      return response;
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
export default PartnerSlice.reducer;
