import { useState } from 'react';
import React from 'react';
import { sideBarItems } from '../../assets/dummy';
import { useLocation, useNavigate } from 'react-router';
import Modal from '../modal/Modal';
import useShowModal from '../../hooks/useShowModal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { logoutAndRedirect } from '../../features/authSlice';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { FaQuestionCircle } from 'react-icons/fa';
import { IoIosLogOut } from 'react-icons/io';
import { Icons } from '../../assets/SVG-Icons';
import { RootState } from '../../app/store';
import { images } from '../../constants/images';
import { FaRegNewspaper } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { IoIosNotifications } from 'react-icons/io';

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return window.matchMedia('(max-width: 767px)').matches;
};

interface SideBarProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
}

const Sidebar: React.FC<SideBarProps> = ({ isDrawerOpen, toggleDrawer }) => {
  const [openItems, setOpenItems] = useState<number[]>([]);
  const userStatus = useSelector((state: RootState) => state.auth.status);
  const BrandInfo = useSelector((state: RootState) => state.partner.brandInfo);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { closeModal, openModal, showModal } = useShowModal();

  const filteredSideBarItems =
    userStatus === 'active'
      ? sideBarItems
      : sideBarItems.filter(item => item.name === 'Dashboard');

  const toggleSubMenu = (itemId: number) => {
    setOpenItems(prevOpenItems => {
      if (prevOpenItems.includes(itemId)) {
        return prevOpenItems.filter(id => id !== itemId);
      } else {
        return [...prevOpenItems, itemId];
      }
    });
  };

  const handleLogout = () => {
    dispatch(logoutAndRedirect(navigate));
  };

  // Handle item click with mobile detection
  const handleItemClick = (itemId: number, path?: string, children?: any) => {
    if (children.length > 0) {
      toggleSubMenu(itemId);
    }

    if (path && isMobileDevice()) {
      toggleDrawer(); // Close drawer on mobile after item click
    }
  };

  // Recursive function to render sidebar items
  const renderSidebarItems = (items: any[]) => {
    console.log('Items', items);
    return items.map(item => (
      <ul key={item.id}>
        <li
          className={`${
            item?.path === pathname
              ? 'bg-[#38C99F]'
              : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
          } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] justify-between`}
          onClick={() => handleItemClick(item?.id, item?.path, item?.children)}
        >
          <div className='flex items-center'>
            {item?.icon &&
              React.createElement(item.icon, {
                className:
                  item?.path === 'marketing-material/qr'
                    ? `h-[17px] w-[30px] mr-2`
                    : `w-[33px] h-[22px] mr-2`,
              })}
            {item?.path ? (
              <Link to={item?.path} className='h-full w-full'>
                {item.name}
              </Link>
            ) : (
              <p>{item.name}</p>
            )}{' '}
          </div>
          {item.children.length > 0 && (
            <>
              {openItems.includes(item.id) ? (
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='ml-4'
                >
                  <path
                    d='M15 12.5L10 7.5L5 12.5'
                    stroke='#98A2B3'
                    strokeWidth='1.38889'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              ) : (
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='ml-4'
                >
                  <path
                    d='M5 7.5L10 12.5L15 7.5'
                    stroke='#98A2B3'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              )}
            </>
          )}
        </li>
        {openItems.includes(item.id) && item.children.length > 0 && (
          <ul className='pl-4'>{renderSidebarItems(item.children)}</ul>
        )}
      </ul>
    ));
  };

  return (
    <>
      <aside
        className={`min-h-screen fixed md:relative w-full md:w-[22rem] bg-white text-primary md:bg-primary md:text-white z-50 ${
          isDrawerOpen ? '' : 'hidden'
        }`}
      >
        <div className='flex items-center h-16 px-3 md:hidden'>
          <button onClick={toggleDrawer}>
            <svg
              className='w-6 h-6'
              fill='#66CC99'
              stroke='#66CC99'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </button>
        </div>
        <div className='h-16 flex items-center px-3 md:bg-[#f8f9ff]'>
          <div className='w-1/5 hidden md:flex justify-center'>
            <button
              onClick={toggleDrawer}
              className='text-gray-900 focus:outline-none'
            >
              <svg
                className='w-6 h-6'
                fill='#333399'
                stroke='#333399'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            </button>
          </div>

          <div className='w-full md:w-4/5 flex justify-center items-center'>
            {BrandInfo?.logo ? (
              <img
                src={BrandInfo?.logo}
                className='w-[95%] max-h-[3rem]'
                alt='Brand Logo'
              />
            ) : (
              Icons.logo({ width: '200', height: '40' })
            )}
          </div>
        </div>
        <div className='overflow-y-auto min-h-[80vh]'>
          <div className='py-5 border-b-2 border-primaryBg'>
            {renderSidebarItems(filteredSideBarItems)}
          </div>

          {userStatus === 'active' && (
            <div className='py-5 border-b-2 border-primaryBg'>
              <ul>
                <Link to={'/profile'}>
                  <li
                    className={`${
                      pathname === '/profile'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaRegUserCircle className='w-[33px] h-[21px] mr-2' />
                    </div>
                    Profile
                  </li>
                </Link>
                <Link to={'/settings'}>
                  <li
                    className={`${
                      pathname === '/settings'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <IoMdSettings className='w-[33px] h-[21px] mr-2' />
                    </div>
                    Settings
                  </li>
                </Link>
                <Link to={'/notifications-inbox'}>
                  <li
                    className={`${
                      pathname === '/notifications-inbox'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <IoIosNotifications className='w-[33px] h-[21px] mr-2' />
                    </div>
                    Notifications
                  </li>
                </Link>
                <Link to={'/support'}>
                  <li
                    className={`${
                      pathname === '/support'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <MdOutlineSupportAgent className='w-[33px] h-[22px] mr-2' />
                    </div>
                    Support
                  </li>
                </Link>
                <Link to={'/faq'}>
                  <li
                    className={`${
                      pathname === '/faq'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaQuestionCircle className='w-[33px] h-[20px] mr-2' />
                    </div>
                    FAQ
                  </li>
                </Link>
                <Link to={'/news'}>
                  <li
                    className={`${
                      pathname === '/news'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaRegNewspaper className='w-[33px] h-[20px] mr-2' />
                    </div>
                    News
                  </li>
                </Link>
              </ul>
            </div>
          )}

          <div className='pt-5 md:mb-[5rem] mb-[7rem]'>
            <button
              onClick={openModal}
              className='hover:bg-[#182f9c] p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] w-full'
            >
              <div>
                <IoIosLogOut className='w-[33px] h-[22px] mr-2' />
              </div>
              Log out
            </button>
          </div>
        </div>
        <div className='absolute bottom-0 mt-4 bg-primary'>
          <div>
            <p className='text-white pt-2 pb-4 px-2 break-words whitespace-normal font-[400] text-center text-[0.75rem] '>
              By using this site, you agree to our{' '}
              <span
                className='underline cursor-pointer'
                onClick={() => navigate('/t&c-privacy#privacyPolicy')}
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <span
                className='underline cursor-pointer'
                onClick={() => navigate('/t&c-privacy#termsOfUse')}
              >
                Terms & Conditions
              </span>
            </p>
            <p className='text-white font-semibold  px-2 break-words whitespace-normal text-center text-[0.75rem] '>
              Powered by{' '}
              <span className='flex justify-center mt-1'>
                {' '}
                <img src={images.WhiteLogo} className='mb-6' alt='' />
              </span>
            </p>
          </div>
        </div>
      </aside>
      <Modal
        isOpen={showModal}
        onClose={closeModal}
        showCloseIcon={false}
        title='Log out from Dashboard '
      >
        <div className='flex items-center justify-center'>
          <button
            className='bg-primary px-7 py-1 text-white rounded'
            onClick={handleLogout}
          >
            Yes
          </button>
          <button
            className='bg-white px-5 py-1 text-pretty'
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
