import React, { useState } from 'react';
import { Icons } from '../../assets/SVG-Icons';
import Button from '../../components/button/Button';
import ImageHoverCard from '../../components/imageHoverCard';
import firstImage from '../../assets/images/dashboard/temp/banner-1.png';
import secondImage from '../../assets/images/dashboard/temp/banner-2.png';
import thirdImage from '../../assets/images/dashboard/temp/banner-3.png';
import fourthImage from '../../assets/images/dashboard/temp/banner-4.png';
import SocialMediaCard from '../../components/SocialMediaCard';

const SocialMedia = () => {
  const [activeTab, setActiveTab] = useState<'pro' | 'content' | 'banners'>(
    'pro'
  );
  // const [showAllSections, setShowAllSections] = useState({
  //   contentSamples: false,
  //   hotelSamples: false,
  //   travelPortalSamples: false,
  //   restaurantSamples: false,
  // });
  const images = [firstImage, secondImage, thirdImage, fourthImage];

  // const toggleShowAll = (
  //   section:
  //     | 'contentSamples'
  //     | 'hotelSamples'
  //     | 'travelPortalSamples'
  //     | 'restaurantSamples'
  // ) => {
  //   setShowAllSections(prevShowAllSections => ({
  //     ...prevShowAllSections,
  //     [section]: !prevShowAllSections[section],
  //   }));
  // };

  return (
    <section className='px-6 mb-16'>
      <div className='mt-5'>
        <h3 className='text-2xl font-bold mb-[1.15rem]'>Social Media</h3>
      </div>

      <div className='flex gap-5 w-full'>
        <Button
          className={` ${
            activeTab === 'pro'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-1/3 font-semibold text-lg`}
          onClick={() => setActiveTab('pro')}
        >
          Pro Tip
        </Button>
        <Button
          className={` ${
            activeTab === 'content'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-1/3 font-semibold text-lg`}
          onClick={() => setActiveTab('content')}
        >
          Content
        </Button>
        <Button
          className={` ${
            activeTab === 'banners'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-1/3 font-semibold text-lg`}
          onClick={() => setActiveTab('banners')}
        >
          Banners
        </Button>
      </div>

      {/* Pro content */}
      {activeTab === 'pro' && (
        <div className='mt-11'>
          <div className='mb-8'>
            <h5 className='text-base font-semibold flex items-center gap-2'>
              <span>{Icons.rightGreenArrow()}</span>
              If your goal is to drive sales or sign-ups, it’s best to use these
              as ads on social media through Ad Manager instead of as posts.
            </h5>
            <p className='ml-5'>
              A good conversion when using ads on social media would be program
              sign-ups or registrations and bookings.
            </p>
          </div>
          <div className='mb-8'>
            <h5 className='text-base font-semibold flex items-center gap-2'>
              <span>{Icons.rightGreenArrow()}</span>
              Boosting your posts is usually good for increasing program
              awareness and audience engagement.
            </h5>
            <p className='ml-5'>
              A good conversion metric when boosting a post would include likes,
              comments, shares, and visits to your app.
            </p>
          </div>
          <div className='mb-8'>
            <h5 className='text-base font-semibold flex items-center gap-2'>
              <span>{Icons.rightGreenArrow()}</span>
              Using hashtags on Facebook will limit exposure to your audience’s
              feed.
            </h5>
            <p className='ml-5'>
              However, the opposite is true for Instagram, where it’s good
              practice to use hashtags to increase exposure and audience
              engagement.
            </p>
          </div>
        </div>
      )}

      {/* Content content */}
      {activeTab === 'content' && (
        <div className='mt-11'>
          <p>
            We’ve created a variety of social media content that you can use as
            posts or ads on Facebook and Instagram. You can add your own links
            to the images and either create custom captions or use the samples
            we’ve provided. Feel free to mix and match the content and CTAs as
            needed to suit your audience.
          </p>
          <div className='mt-10'>
            <div className='flex justify-between items-center max-w-[69rem]'>
              <h4 className='font-bold text-lg'>
                Download the app content samples for social media
              </h4>
              {/* <button
                onClick={() => toggleShowAll('contentSamples')}
                className='text-[#1133BB]'
              >
                {showAllSections.contentSamples ? 'Collapse' : 'View all'}
              </button> */}
            </div>
            <div className='flex gap-4 mt-4 flex-wrap w-full'>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 1'
                  message='Start saving today! Download our loyalty app and watch your cashback rewards grow with every purchase. 🙌💰'
                  ctaText='Download our app'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 2'
                  message='Shop, save, and earn! Download our loyalty app to unlock exclusive cashback rewards. 📲💵'
                  ctaText='Download our app'
                />
              </div>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 3'
                  message='Why wait? Download our loyalty app today and start earning cashback rewards with every purchase! 💸✨'
                  ctaText='Download now'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 4'
                  message='Turn everyday purchases into cashback! Download the app and start earning now. 💰➡📲'
                  ctaText='Download our app'
                />
              </div>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 5'
                  message='Shop smarter, not harder! Download our app and earn cashback rewards with every swipe. 💸'
                  ctaText='Download now'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 6'
                  message='Earn while you spend! Download the app and enjoy real cash rewards at thousands of stores. 💰'
                  ctaText='Download our app'
                />
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <div className='flex justify-between items-center max-w-[69rem]'>
              <h4 className='font-bold text-lg'>
                Book hotel content samples for social media{' '}
              </h4>
              {/* <button
                onClick={() => toggleShowAll('hotelSamples')}
                className='text-[#1133BB]'
              >
                {showAllSections.hotelSamples ? 'Collapse' : 'View all'}
              </button> */}
            </div>
            <div className='flex gap-4 mt-4 flex-wrap w-full'>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 1'
                  message='Get cashback on more than 850K hotels worldwide - deals that can’t be found anywhere else!'
                  ctaText='Book Now, Get Cashback, Find Hotels'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 2'
                  message='Get cashback on already discounted hotel inventory and book risk-free with the cancellation and refund policies. Simply search your destination of choice, book, save, and enjoy.'
                  ctaText='Book Now, Get Cash Back, Find Hotels'
                />
              </div>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 3'
                  message='Get cashback on already discounted hotel inventory and book risk-free with the cancellation and refund policies. Simply search your destination of choice, book, save, and enjoy.'
                  ctaText='Book Now, Get Cashback, Find Hotels'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 4'
                  message='Get cashback on already discounted hotel inventory and book risk-free with the cancellation and refund policies. Simply search your destination of choice, book, save, and enjoy.'
                  ctaText='Book Now, Get Cashback, Find Hotels'
                />
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <div className='flex justify-between items-center max-w-[69rem]'>
              <h4 className='font-bold text-lg'>
                Promoting the in-app travel portal content for social media
              </h4>
              {/* <button
                onClick={() => toggleShowAll('travelPortalSamples')}
                className='text-[#1133BB]'
              >
                {showAllSections.travelPortalSamples ? 'Collapse' : 'View all'}
              </button> */}
            </div>
            <div className='flex gap-4 mt-4 flex-wrap w-full'>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 1'
                  message='Book smarter! Get cashback on hotels, flights, car rentals, and more with our travel portal. 🌍✈️💰'
                  ctaText='Book now'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 2'
                  message=' 🚗🏖️ Whether you’re booking a hotel, flight, or even a cruise, our members-only travel portal helps you save big and earn cashback. Travel smarter, not harder!'
                  ctaText='Save More on Every Booking'
                />
              </div>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 3'
                  message='✈️🌍 Ready to save on your next getaway? Our members-only travel portal lets you earn cashback on hotels, flights, cruises, and more. Start planning your next adventure today! 🏨'
                  ctaText='Start Saving on Your Next Trip'
                />
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <div className='flex justify-between items-center max-w-[69rem]'>
              <h4 className='font-bold text-lg'>
                Promoting the in-app travel portal content for social media{' '}
              </h4>
              {/* <button
                onClick={() => toggleShowAll('restaurantSamples')}
                className='text-[#1133BB]'
              >
                {showAllSections.restaurantSamples ? 'Collapse' : 'View all'}
              </button> */}
            </div>
            <div className='flex gap-4 mt-4 flex-wrap w-full'>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 1'
                  message='🍕🥗 Indulge in your favorite meals and get rewarded! With cashback at thousands of restaurants, every bite counts towards your savings.'
                  ctaText='Get Cashback on Every Meal!'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 2'
                  message='🍔🎉 Who doesn’t love a good meal with a side of savings? Earn cashback at over 20,000 restaurants and enjoy your dining experience even more!'
                  ctaText='Taste the Savings!'
                />
              </div>
              <div className='grid grid-cols-1 w-full xl:grid-cols-2 gap-4'>
                <SocialMediaCard
                  pointText='Sample Messaging 3'
                  message='💰 Dine out, earn cashback! It’s that simple. Enjoy meals at your favorite spots and watch your savings grow. Ready to eat smart?'
                  ctaText='Dine, Save, Repeat!'
                />
                <SocialMediaCard
                  pointText='Sample Messaging 4'
                  message='Why just dine when you can earn cashback too? Enjoy meals at over 20,000 restaurants and watch your savings grow!'
                  ctaText='Eat More, Save More!'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Content banners */}
      {activeTab === 'banners' && (
        <div className='mt-11'>
          <p>
            Download ready-made social media banners to promote your app and
            drive customer engagement. Use them to encourage purchases and
            increase interaction within the app.
          </p>
          <div className='flex gap-9 mt-14 flex-wrap'>
            {Array.from({ length: 4 }, (_, index) => (
              <ImageHoverCard
                key={index}
                imageUrl={images[index]}
                altText={`Product Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default SocialMedia;
