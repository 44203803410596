import React from 'react';
import ImageHoverCard from '../../components/imageHoverCard';
import buyingCoffee from '../../assets/images/dashboard/product-images/buying coffee_2390088981.jpg';
import coupleUsingCard from '../../assets/images/dashboard/product-images/couple using card_2491715627.jpg';
import gettingCoffee from '../../assets/images/dashboard/product-images/getting coffee_2475095811.jpg';
import groupHoldingPhones from '../../assets/images/dashboard/product-images/group holding phones_2138874521.jpg';
import manHoldingPhone from '../../assets/images/dashboard/product-images/man holding phone_598990760.jpg';
import manWithPhone2 from '../../assets/images/dashboard/product-images/man with phone_2_1854409381.jpg';
import payingForCoffee from '../../assets/images/dashboard/product-images/paying for coffee_2349014269.jpg';
import payingWithPhone2 from '../../assets/images/dashboard/product-images/paying with phone_2_2423774009.jpg';
import payingWithPhone from '../../assets/images/dashboard/product-images/paying with phone_2383450641.jpg';
import poolView from '../../assets/images/dashboard/product-images/pool view_1437805796.jpg';
import womanLookingAtPhone2 from '../../assets/images/dashboard/product-images/woman looking at phone_2_2492372015.jpg';
import womanLookingAtPhone from '../../assets/images/dashboard/product-images/woman looking at phone_2477174817.jpg';
import womanPayingWithCard from '../../assets/images/dashboard/product-images/woman paying with card_2487419519.jpg';
import womanPayingWithPhone from '../../assets/images/dashboard/product-images/woman paying with phone_2413955101.jpg';
import womanPaying from '../../assets/images/dashboard/product-images/woman paying_2244005477.jpg';
import womanUsingCard2 from '../../assets/images/dashboard/product-images/woman using card_2_2401097807.jpg';
import womanUsingCard3 from '../../assets/images/dashboard/product-images/woman using card_3_2422947503.jpg';
import womanUsingCard from '../../assets/images/dashboard/product-images/woman using card_2274892619.jpg';

const ProductImages = () => {
  const images = [
    buyingCoffee,
    coupleUsingCard,
    gettingCoffee,
    groupHoldingPhones,
    manHoldingPhone,
    manWithPhone2,
    payingForCoffee,
    payingWithPhone2,
    payingWithPhone,
    poolView,
    womanLookingAtPhone2,
    womanLookingAtPhone,
    womanPayingWithCard,
    womanPayingWithPhone,
    womanPaying,
    womanUsingCard2,
    womanUsingCard3,
    womanUsingCard,
  ];
  return (
    <section>
      <div className='mt-10'>
        <h3 className='text-2xl font-bold mb-[1.15rem]'>Product Images</h3>
        <p>
          We’ve selected a variety of images that are available for download to
          support your marketing and promotional efforts. These images will be
          refreshed regularly, so make sure to check back for new additions.
        </p>
      </div>
      <div className='flex gap-9 mt-14 flex-wrap'>
        {images.map((img, index) => (
          <ImageHoverCard
            key={index}
            imageUrl={img}
            altText={`Product Image ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default ProductImages;
